/* It will copy to GV-Map when excute "UploadAWS.cmd". So don't modify in GV-Map project. */

body {
    --gv-body-font-family: 'Microsoft JhengHei', 微軟正黑體, 'Segoe UI', SegoeUI, Helvetica, Arial, sans-serif;
    --gv-body-font-size: 14px;

    --gv-body-bg: #F4F7FA;
    --gv-body-bg-rgb: 244, 247, 250;
    --gv-body-color: rgba(0, 0, 0, 0.85);

    --gv-user-avatar-bg: #E4E7EC;
    --gv-snapshot-bg: #E4E7EC;

    /* scrollbar */
    --gv-scrollbar-bg: #C5C8CD;
    --gv-scrollbar-width: 8px;
    --gv-scrollbar-border-radius: 10px;

    /* tooltip */
    --gv-tooltip-border-radius: 4px;
    --gv-tooltip-bg: #282828;
    --gv-tooltip-color: #d9d9d9;

    /* modal */
    --gv-modal-border-radius: 0.5rem;
    --gv-modal-bg: #F4F7FA;
    --gv-modal-border: 1px solid #DFE1E5;
    --gv-modal-close-color: #282828;
    --gv-modal-close-hover-color: #5683AF;
    --gv-modal-close-active-color: #2C649B;

    /* tab */
    --gv-tab-color: rgba(0, 0, 0, 0.85);
    --gv-tab-hover-color: #5683AF;
    --gv-tab-selected-color: #2C649B;

    /* dropdown menu */
    --gv-dropdown-menu-bg: #FFF;
    --gv-dropdown-menu-color: rgba(0, 0, 0, 0.85);
    --gv-dropdown-menu-border: 1px solid #DFE1E5;
    --gv-dropdown-menu-border-color: #DFE1E5;
    --gv-dropdown-menu-border-radius: 6px;
    --gv-dropdown-menu-box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    --gv-dropdown-menu-item-border-radius: 4px;
    --gv-dropdown-menu-item-hover-bg: #E8F0F9;
    --gv-dropdown-menu-item-hover-color: rgba(0, 0, 0, 0.85);
    --gv-dropdown-menu-item-selected-bg: #E3EDF9;
    --gv-dropdown-menu-item-selected-color: rgba(0, 0, 0, 0.85);
    --gv-dropdown-menu-item-disabled-color: #28282866;
    --gv-dropdown-menu-item-separator-color: #DFE1E5;

    /* list item */
    --gv-list-item-border-color: #DFE1E5;
    --gv-list-item-color: rgba(0, 0, 0, 0.85);
    --gv-list-item-bg: #fff;
    --gv-list-item-even-bg: #fafafa;
    --gv-list-item-hover-bg: #E8F0F9;
    --gv-list-item-selected-color: rgba(0, 0, 0, 0.85);
    --gv-list-item-selected-bg: #E3EDF9;
    --gv-list-item-disabled-color: rgba(0,0,0,.25);

    /* log item */
    --gv-log-item-color: #282828;
    --gv-log-item-bg: #fff;
    --gv-log-item-hover-color: #282828;
    --gv-log-item-hover-bg: #E8F0F9;
    --gv-log-item-selected-color: #282828;
    --gv-log-item-selected-bg: #E3EDF9; 

    /* checkbox */
    --gv-checkbox-bg: #fff;
    --gv-checkbox-border: #A1A3A8;
    --gv-checkbox-border-radius: 4px;
    --gv-checkbox-hover-bg: #fff;
    --gv-checkbox-hover-border: #5474A5;
    --gv-checkbox-checked-bg: #5474A5;
    --gv-checkbox-checked-border: #5474A5;
    --gv-checkbox-checked-hover-bg: #5683AF;
    --gv-checkbox-checked-hover-border: #5683AF;

    /* slider */
    --gv-slider-rail-bg: #DFE1E5;
    --gv-slider-rail-hover-bg: #A1A3A8;
    --gv-slider-rail-disabled-bg: #e9ecef;
    --gv-slider-track-bg: #5474A5;
    --gv-slider-track-hover-bg: #5683AF;
    --gv-slider-track-disabled-bg: #A5BDD6;

    /* switch */
    --gv-switch-color: rgba(0,0,0,.85);
    --gv-switch-bg: #A1A3A8;
    --gv-switch-checked-bg: #5474A5;
    --gv-switch-handle-bg: #fff;

    /* input */
    --gv-input-border-radius: 6px;
    --gv-input-color: #282828;
    --gv-input-bg: #fff;
    --gv-input-border-color: #DFE1E5;
    --gv-input-hover-bg: #fff;
    --gv-input-hover-border-color: #5683AF;
    --gv-input-disabled-color: rgba(0,0,0,.25);
    --gv-input-disabled-bg: #e9ecef;
    --gv-input-placeholder-color: #bfbfbf;

    /* button */
    --gv-btn-border-radius: 6px;

    --gv-btn-primary-color: #fff;
    --gv-btn-primary-bg: #5474A5 ;
    --gv-btn-primary-hover-bg: #5683AF ;
    --gv-btn-primary-active-bg: #2C649B ;
    --gv-btn-primary-disabled-bg: #B5C2D8;
    --gv-btn-primary-disabled-color: #F8FAFC;

    --gv-btn-secondary-color: #5474A5;
    --gv-btn-secondary-bg: #F4F7FA;
    --gv-btn-secondary-border-color: #5474A5;
    --gv-btn-secondary-hover-bg: #E8F0F9;
    --gv-btn-secondary-hover-color: #5474A5;
    --gv-btn-secondary-active-bg: #E3EDF9;
    --gv-btn-secondary-disabled-bg: #EFF3F9 ;
    --gv-btn-secondary-disabled-color: rgba(84, 116, 165, 0.4);
    --gv-btn-secondary-disabled-border-color: rgba(84, 116, 165, 0.4);

    --gv-btn-danger-color: #fff;
    --gv-btn-danger-bg: #E81710;
    --gv-btn-danger-hover-bg: #EB4E49;
    --gv-btn-danger-active-bg: #CB0903;
    --gv-btn-danger-disabled-bg: rgba(232, 23, 16, 0.4);
    --gv-btn-danger-disabled-color: #d9d9d9;

    --gv-text-btn-color: #282828;
    --gv-text-btn-bg: transparent;
    --gv-text-btn-hover-color: #5683AF;
    --gv-text-btn-hover-bg: #E8F0F9;
    --gv-text-btn-active-color: #2C649B;
    --gv-text-btn-active-bg: #E3EDF9;
    --gv-text-btn-disabled-color: #d9d9d9;

    /* segmented */
    --gv-segmented-border-radius: 6px;
    --gv-segmented-color: #5474A5;
    --gv-segmented-bg: #E2E6ED;
    --gv-segmented-item-border-radius: 4px;
    --gv-segmented-item-hover-color: #5683AF;
    --gv-segmented-item-hover-bg: #E8F0F9;
    --gv-segmented-item-active-color: #2C649B;
    --gv-segmented-item-active-bg: #E3EDF9;
    --gv-segmented-item-selected-color: #fff;
    --gv-segmented-item-selected-bg: #5474A5;
    --gv-segmented-item-disabled-color: rgba(0,0,0,.25);

    --gv-divider-color: #DFE1E5;

    /* Announcement */
    --gv-Announcement-border-radius: 0.5rem;
    --gv-Announcement-bg: #fff;
    --gv-Announcement-border: 1px solid #DFE1E5;
    --gv-Announcement-box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
    
    /* sidebar */
    --gv-sidebar-logo-bg: #59a9ef;
    --gv-sidebar-collapse-width: 80px;
    --gv-sidebar-expand-width: 210px;
    --gv-sidebar-item-height: 52px;
    --gv-sidebar-icon-size: 24px;
    --gv-sidebar-avatar-size: 48px;
    
    --gv-sidebar-bg: #5474a5;
    --gv-sidebar-color: #fff;
    --gv-sidebar-item-selected-bg: #91A6C6;
    --gv-sidebar-item-selected-color: #fff;
    --gv-sidebar-item-hover-bg: rgba(255,255,255,.6);
    --gv-sidebar-item-hover-color: #fff;
    --gv-sidebar-item-active-bg: #fff;
    --gv-sidebar-item-active-color: #5474A5;

    --gv-sidebar-btn-bg: #fff;
    --gv-sidebar-btn-arrow-color: #5474A5;

    /* service panel */
    --gv-service-margin: 6px;
    --gv-service-padding: 6px;
    --gv-service-border-width: 1px;
    --gv-service-border-radius: 0.5rem;
    --gv-service-item-gap: 0.5rem;
    --gv-service-item-size: 7rem;
    --gv-service-item-border-radius: 1rem;
    --gv-service-item-font-size: 12px;
    --gv-service-item-line-height: 18px;
    --gv-service-icon-size: 48px;
    
    --gv-service-color: #405D8A;
    --gv-service-bg: #ebeef5;
    --gv-service-border: var(--gv-service-border-width) solid #ebeef5;
    --gv-service-box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.16);
    --gv-service-icon-color: #5196FF;
    --gv-service-item-selected-bg: rgba(212, 218, 227, 1);
    --gv-service-item-hover-bg: rgba(255, 255, 255, .6);
    --gv-service-item-active-bg: rgba(255, 255, 255, 1);
    --gv-service-disabled-color: #405e8a66;
    --gv-service-icon-disabled-color: #5196FF66;


    /* account panel */
    --gv-account-margin: 6px;
    --gv-account-padding: 20px 20px 0;
    --gv-account-border-width: 1px;
    --gv-account-border-radius: 0.5rem;
    --gv-account-avatar-size: 60px;

    --gv-account-bg: #ebeef5;
    --gv-account-border: var(--gv-account-border-width) solid #ebeef5;
    --gv-account-box-shadow: 0px 10px 20px rgb(0 0 0 / 35%);
    --gv-account-email-color: #707070;
    --gv-account-link-color: #5474A5;
    --gv-account-link-hover-color: #5683AF;
    --gv-account-link-active-color: #2C649B;
}

body.dark {
    --gv-body-bg: #131313;
    --gv-body-bg-rgb: 19, 19, 19;
    --gv-body-color: #d9d9d9;

    --gv-user-avatar-bg: #404247;
    --gv-snapshot-bg: #404247;

    /* scrollbar */
    --gv-scrollbar-bg: #404247;

    /* tooltip */
    --gv-tooltip-bg: rgb(219, 224, 234);
    --gv-tooltip-color: #282828;

    /* modal */
    --gv-modal-bg: #131313;
    --gv-modal-border: 1px solid #404247;
    --gv-modal-close-color: #d9d9d9;
    --gv-modal-close-hover-color: #2C649B;
    --gv-modal-close-active-color: #4B97F1;

    /* tab */
    --gv-tab-color: #d9d9d9;
    --gv-tab-hover-color: #2C649B;
    --gv-tab-selected-color: #468BE2;

    /* dropdown menu */
    --gv-dropdown-menu-bg: #3B3C43;
    --gv-dropdown-menu-color: #d9d9d9;
    --gv-dropdown-menu-border: 1px solid #404247;
    --gv-dropdown-menu-border-color: #404247;
    --gv-dropdown-menu-item-hover-bg: rgba(63, 73, 86);
    --gv-dropdown-menu-item-hover-color: #fff;
    --gv-dropdown-menu-item-selected-bg: #404E62;
    --gv-dropdown-menu-item-selected-color: #fff;
    --gv-dropdown-menu-item-disabled-color: rgb(217, 217, 217, .4);
    --gv-dropdown-menu-item-separator-color: #404247;

    /* list item */
    --gv-list-item-border-color: #404247;
    --gv-list-item-color: #d9d9d9;
    --gv-list-item-bg: #232323;
    --gv-list-item-even-bg: #1b1b1b;
    --gv-list-item-hover-bg: #3F4956;
    --gv-list-item-selected-color: rgba(255, 255, 255, 0.85);
    --gv-list-item-selected-bg: #404E62;
    --gv-list-item-disabled-color: rgb(217, 217, 217, .2);

    /* log item */
    --gv-log-item-color: #d9d9d9;
    --gv-log-item-bg: #131313;
    --gv-log-item-hover-color: #fff;
    --gv-log-item-hover-bg: #3F4956;
    --gv-log-item-selected-color: #fff;
    --gv-log-item-selected-bg: #404E62;

    /* checkbox */
    --gv-checkbox-bg: #232323;
    --gv-checkbox-border: #A1A3A8;
    --gv-checkbox-border-radius: 4px;
    --gv-checkbox-hover-bg: #232323;
    --gv-checkbox-hover-border: #4C94E9;
    --gv-checkbox-checked-bg: #4C94E9;
    --gv-checkbox-checked-border: #4C94E9;
    --gv-checkbox-checked-hover-bg: #2C649B;
    --gv-checkbox-checked-hover-border: #2C649B;
    
    /* slider */
    --gv-slider-rail-bg: #313336;
    --gv-slider-rail-disabled-bg: #232323;
    --gv-slider-track-bg: #4C94E9;
    --gv-slider-track-hover-bg: #468BE2;
    --gv-slider-track-disabled-bg: #16518C;

    /* switch */
    --gv-switch-color: rgba(0,0,0,.85);
    --gv-switch-bg: #A1A3A8;
    --gv-switch-checked-bg: #4C94E9;
    --gv-switch-handle-bg: #fff;

    /* input */
    --gv-input-color: #d9d9d9;
    --gv-input-bg: #313336;
    --gv-input-border-color: #313336;
    --gv-input-hover-bg: #313336;
    --gv-input-hover-border-color: #468BE2;
    --gv-input-disabled-color: rgba(255,255,255,.25);
    --gv-input-disabled-bg: #232323;
    --gv-input-placeholder-color: #777;

    /* button */
    --gv-btn-primary-color: #fff;
    --gv-btn-primary-bg: #4C94E9;
    --gv-btn-primary-hover-bg: #2C649B;
    --gv-btn-primary-active-bg: #468BE2;
    --gv-btn-primary-disabled-bg: #2D4569;
    --gv-btn-primary-disabled-color: #717171;

    --gv-btn-secondary-color: #4C94E9;
    --gv-btn-secondary-bg: transparent;
    --gv-btn-secondary-border-color: #4C94E9;
    --gv-btn-secondary-hover-bg: #0D1D2F;
    --gv-btn-secondary-hover-color: #4C94E9;
    --gv-btn-secondary-active-bg: #122943;
    --gv-btn-secondary-disabled-bg: transparent;
    --gv-btn-secondary-disabled-color: #2D4569;
    --gv-btn-secondary-disabled-border-color: #2D4569;

    --gv-btn-danger-color: #fff;
    --gv-btn-danger-bg: #FF2323;
    --gv-btn-danger-hover-bg: #B81E1E;
    --gv-btn-danger-active-bg: #CB0903;
    --gv-btn-danger-disabled-bg: rgba(255, 35, 35, 0.4);
    --gv-btn-danger-disabled-color: #A1A3A8;

    --gv-text-btn-color: #d9d9d9;
    --gv-text-btn-bg: transparent;
    --gv-text-btn-hover-color: #fff;
    --gv-text-btn-hover-bg: #3F4956;
    --gv-text-btn-active-color: #fff;
    --gv-text-btn-active-bg: #404E62;
    --gv-text-btn-disabled-color: #A1A3A8;

    /* segmented */
    --gv-segmented-color: #d9d9d9;
    --gv-segmented-bg: #313336;
    --gv-segmented-item-hover-color: #fff;
    --gv-segmented-item-hover-bg: #3F4956;
    --gv-segmented-item-active-color: #fff;
    --gv-segmented-item-active-bg: #404E62;
    --gv-segmented-item-selected-color: #fff;
    --gv-segmented-item-selected-bg: #4C94E9;
    --gv-segmented-item-disabled-color: rgba(255,255,255,.15);

    --gv-divider-color: #404247;

    /* Announcement */
    --gv-Announcement-bg: #282828;
    --gv-Announcement-border: 1px solid #777;
    --gv-Announcement-box-shadow: 0 3px 6px -4px rgba(255, 255, 255,.12), 0 6px 16px 0 rgba(255, 255, 255,.08), 0 9px 28px 8px rgba(255, 255, 255,.05);

    /* sidebar */
    --gv-sidebar-bg: #232323;
    --gv-sidebar-color: #d9d9d9;
    --gv-sidebar-item-selected-bg: #4C94E9;
    --gv-sidebar-item-selected-color: #fff;
    --gv-sidebar-item-hover-bg: rgba(64, 78, 98, 0.6);
    --gv-sidebar-item-hover-color: #fff;
    --gv-sidebar-item-active-bg: #404E62;
    --gv-sidebar-item-active-color: #fff;

    --gv-sidebar-btn-bg: #131313;
    --gv-sidebar-btn-arrow-color: #fff;

    /* service panel */
    --gv-service-color: #d9d9d9;
    --gv-service-bg: #1e1e1e;
    --gv-service-border: var(--gv-service-border-width) solid #313336;
    --gv-service-box-shadow: 2px 2px 6px 0 rgba(255, 255, 255, 0.06);
    --gv-service-icon-color: #5196FF;
    --gv-service-item-selected-bg: rgba(19, 19, 19, 0.7);
    --gv-service-item-hover-bg: rgba(64, 78, 98, 0.6);
    --gv-service-item-active-bg: rgba(64, 78, 98, 1);
    --gv-service-disabled-color: #d9d9d966;
    --gv-service-icon-disabled-color: #5196FF66;


    /* account panel */
    --gv-account-bg: #1e1e1e;
    --gv-account-border: var(--gv-account-border-width) solid #313336;
    --gv-account-box-shadow: 0px 10px 20px rgb(255, 255, 255 / 35%);
    --gv-account-email-color: #A1A3A8;
    --gv-account-link-color: #4C94E9;
    --gv-account-link-hover-color: #2C649B ;
    --gv-account-link-active-color: #468BE2;
}

body {
    color: var(--gv-body-color);
    background-color: var(--gv-body-bg);
    font-family: var(--gv-body-font-family);
    font-size: var(--gv-body-font-size);
}

::-webkit-scrollbar {
    width: var(--gv-scrollbar-width);
    height: var(--gv-scrollbar-border-radius);
}
::-webkit-scrollbar-thumb  {
    background-color: var(--gv-scrollbar-bg);
    -webkit-border-radius: var(--gv-scrollbar-border-radius);
    border-radius: var(--gv-scrollbar-border-radius);
}

/* user avatar */
.gv-user-avatar {
    background-color: var(--gv-user-avatar-bg);
    background-image: url(../src/images/user_noimg_no_bg.svg);
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    overflow: hidden;
    flex: 0 0 auto;
}
.gv-user-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    visibility: hidden;
}
.gv-user-avatar img.loaded {
    visibility: visible;
}

/* snapshot */
.gv-snapshot {
    background-color: var(--gv-snapshot-bg);
    background-image: url(../src/images/snapshot_empty_no_bg.svg);
    background-size: 25%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0.5rem;
    overflow: hidden;
}
.gv-snapshot.lake-snapshot {
    background-image: url(../src/images/snapshot_lack_no_bg.svg);
}
.gv-snapshot.no-license {
    background-image: url(../src/images/snapshot_no_license_no_bg.svg);
}
.gv-snapshot.no-snapshot {
    background-image: url(../src/images/no_snapshot_no_bg.svg);
}
.gv-snapshot > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    visibility: hidden;
}
.gv-snapshot > img.loaded {
    visibility: visible;
}


/* service panel */
.gv-service-panel {
    position: absolute;
    top: 0;
    left: 100%;
    background-color: var(--gv-service-bg);
    border: var(--gv-service-border);
    margin: var(--gv-service-margin);
    padding: var(--gv-service-padding);
    border-radius: var(--gv-service-border-radius);
    box-shadow: var(--gv-service-box-shadow);
    
    width: calc(var(--gv-service-item-size) * 3 + var(--gv-service-padding) * 2 + var(--gv-service-item-gap) * 2 + var(--gv-service-border-width) * 2);
    display: flex;
    flex-wrap: wrap;
    grid-gap: var(--gv-service-item-gap);

    transform: scale(0);
    transform-origin: left top;
    transition: transform .3s;
}
.gv-service-panel.show {
    transform: scale(1);
}

.gv-service-panel > a {
    width: var(--gv-service-item-size);
    height: var(--gv-service-item-size);
    border-radius: var(--gv-service-item-border-radius);
    line-height: var(--gv-service-item-line-height);
    padding-top: calc(var(--gv-service-item-line-height) / 2);

    font-size: var(--gv-service-item-font-size);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: break-spaces;
}
.gv-service-panel > a, .gv-service-panel > a:active, .gv-service-panel > a.active, .gv-service-panel > a:hover, .gv-service-panel > a:visited {
    color: var(--gv-service-color);
}
.gv-service-panel > a:hover:not([disabled]) {
    background-color: var(--gv-service-item-hover-bg);
}
.gv-service-panel > a:active:not([disabled]) {
    background-color: var(--gv-service-item-active-bg);
}
.gv-service-panel > a.active:not([disabled]) {
    background-color: var(--gv-service-item-selected-bg);
}
.gv-service-panel > a[disabled] {
    cursor: not-allowed;
    color: var(--gv-service-disabled-color);
}

.gv-service-panel > a svg {
    width: var(--gv-service-icon-size);
    height: var(--gv-service-icon-size);
}
.gv-service-panel > a svg path {
    fill: var(--gv-service-icon-color);
}
.gv-service-panel > a[disabled] svg path {
    fill: var(--gv-service-icon-disabled-color);
}
.gv-service-panel > a span {
    height: calc(var(--gv-service-item-line-height) * 2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.gv-service-panel > a span.beta::after {
    content: '(Beta)';
}

/* account panel */
.gv-account-panel {
    position: absolute;
    bottom: 0;
    left: 100%;
    background-color: var(--gv-account-bg);
    border: var(--gv-account-border);
    margin: var(--gv-account-margin);
    padding: var(--gv-account-padding);
    border-radius: var(--gv-account-border-radius);
    box-shadow: var(--gv-account-box-shadow);

    display: flex;
    flex-direction: column;
    align-items: center;

    transform: scale(0);
    transform-origin: left bottom;
    transition: transform .3s;
}
.gv-account-panel.show {
    transform: scale(1);
}

.gv-account-panel > .gv-user-avatar {
    margin: 20px 0 10px;
    width: var(--gv-account-avatar-size);
    height: var(--gv-account-avatar-size);
}

.gv-account-panel > .name {
    font-size: 18px;
    font-weight: bold;
}

.gv-account-panel > .email {
    color: var(--gv-account-email-color);
}

.gv-account-panel > .setting {
    padding: 10px 0;
    border-bottom: 1px solid #a1a3a8;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    min-width: 160px;
}
.gv-account-panel > .setting svg {
    width: 16px;
    height: 16px;
}

.gv-account-panel > .logout {
    margin: 1.2rem;
    width: 100%;
    text-align: center;
}

.gv-account-panel > a {
    display: flex;
    justify-content: center;
    align-items: center;
}
.gv-account-panel > a, .gv-account-panel > a svg {
    color: var(--gv-account-link-color);
}
.gv-account-panel > a:hover, .gv-account-panel > a:hover svg {
    color: var(--gv-account-link-hover-color);
}
.gv-account-panel > a:active, .gv-account-panel > a:active svg {
    color: var(--gv-account-link-active-color);
}
.gv-account-panel > a svg path {
    fill: currentColor;
}



.gv-announcement {
    position: relative;
    display: flex;
    flex-direction: column;
    grid-gap: 0.5em;
    max-width: 600px;
}
.gv-announcement-wrap {
    font-size: 16px;
    display: flex;
    align-items: center;
    grid-gap: 0.5em;
    text-align: left;
}
.gv-announcement .icon {
    width: 32px;
    height: 32px;
    flex: 0 0 auto;
}
.gv-announcement .content {
    flex: 1 1 auto;
}
.gv-announcement .content ul {
    margin: 1em 0;
}
.gv-announcement .close {
    cursor: pointer;
    align-self: flex-start;
}
.gv-announcement .dont-show {
    transform: scale(0.9);
    transform-origin: left;
    opacity: 0.8;
}
