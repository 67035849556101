/* It will copy to GV-Map when excute "UploadAWS.cmd". So don't modify in GV-Map project. */

.ant-col {
    font-family: var(--gv-body-font-family);
}

/* button */
.ant-btn {
    color: var(--gv-btn-primary-color);
}
.ant-btn:not(.ant-btn-circle) {
    border-radius: var(--gv-btn-border-radius);
}
.ant-btn:not(:disabled):focus-visible {
    outline: none;
}

.ant-btn.ant-btn-default {
    color: var(--gv-btn-secondary-color);
    background-color: var(--gv-btn-secondary-bg);
    border-color: var(--gv-btn-secondary-border-color);
}
.ant-btn.ant-btn-default:not(:disabled):hover {
    color: var(--gv-btn-secondary-hover-color);
    background-color: var(--gv-btn-secondary-hover-bg);
    border-color: var(--gv-btn-secondary-border-color);
}
.ant-btn.ant-btn-default:not(:disabled):active {
    color: var(--gv-btn-secondary-hover-color);
    background-color: var(--gv-btn-secondary-active-bg);
    border-color: var(--gv-btn-secondary-border-color);
}
.ant-btn-default:disabled, .ant-btn-default:disabled:hover, .ant-btn-default:disabled:active, .ant-btn-default:disabled:focus {
    color: var(--gv-btn-secondary-disabled-color);
    background-color: var(--gv-btn-secondary-disabled-bg);
    border-color: var(--gv-btn-secondary-disabled-border-color);
}


.ant-btn.ant-btn-primary:not(.ant-btn-dangerous) {
    color: var(--gv-btn-primary-color);
    background-color: var(--gv-btn-primary-bg);
    border-color: var(--gv-btn-primary-bg);
}
.ant-btn.ant-btn-primary:not(:disabled):not(.ant-btn-dangerous):hover {
    color: var(--gv-btn-primary-color);
    background-color: var(--gv-btn-primary-hover-bg);
    border-color: var(--gv-btn-primary-hover-bg);
}
.ant-btn.ant-btn-primary:not(:disabled):not(.ant-btn-dangerous):active {
    color: var(--gv-btn-primary-color);
    background-color: var(--gv-btn-primary-active-bg);
    border-color: var(--gv-btn-primary-active-bg);
}
.ant-btn-primary:disabled:not(.ant-btn-dangerous), .ant-btn-primary:disabled:not(.ant-btn-dangerous):hover, .ant-btn-primary:disabled:not(.ant-btn-dangerous):active, .ant-btn-primary:disabled:not(.ant-btn-dangerous):focus {
    color: var(--gv-btn-primary-disabled-color);
    background-color: var(--gv-btn-primary-disabled-bg);
    border-color: var(--gv-btn-primary-disabled-bg);
}
.ant-btn.ant-btn-primary:not(.orig-color) svg > * {
    fill: currentColor;
}

.ant-btn-primary.ant-btn-dangerous {
    color: var(--gv-btn-danger-color);
    background-color: var(--gv-btn-danger-bg);
    border-color: var(--gv-btn-danger-bg);
}
.ant-btn-primary.ant-btn-dangerous:not(:disabled):hover {
    color: var(--gv-btn-danger-color);
    background-color: var(--gv-btn-danger-hover-bg);
    border-color: var(--gv-btn-danger-hover-bg);
}
.ant-btn-primary.ant-btn-dangerous:not(:disabled):active {
    color: var(--gv-btn-danger-color);
    background-color: var(--gv-btn-danger-active-bg);
    border-color: var(--gv-btn-danger-active-bg);
}
.ant-btn-primary.ant-btn-dangerous:disabled, .ant-btn-primary.ant-btn-dangerous:disabled:hover, .ant-btn-primary.ant-btn-dangerous:disabled:active, .ant-btn-primary.ant-btn-dangerous:disabled:focus {
    color: var(--gv-btn-danger-disabled-color);
    background-color: var(--gv-btn-danger-disabled-bg);
    border-color: var(--gv-btn-danger-disabled-bg);
}

.ant-btn-text {
    color: var(--gv-text-btn-color);
    background-color: var(--gv-text-btn-bg);
}
.ant-btn-text:not(:disabled):hover, .ant-btn-text:focus {
    color: var(--gv-text-btn-hover-color);
    background-color: var(--gv-text-btn-hover-bg);
}
.ant-btn-variant-text:not(:disabled):not(.ant-btn-disabled):hover {
    color: var(--gv-text-btn-hover-color);
    background-color: var(--gv-text-btn-hover-bg);
}
.ant-btn-text.ant-btn-dangerous:not(:disabled):hover {
    color: var(--gv-btn-danger-hover-bg);
    background-color: var(--gv-text-btn-hover-bg);
}
.ant-btn-text:not(:disabled):active {
    color: var(--gv-text-btn-active-color);
    background-color: var(--gv-text-btn-active-bg);
}
.ant-btn-text.ant-btn-dangerous:not(:disabled):active {
    color: var(--gv-btn-danger-active-bg);
    background-color: var(--gv-text-btn-active-bg);
}
.ant-btn-text:disabled, .ant-btn-text:disabled:hover, .ant-btn-text:disabled:active, .ant-btn-text:disabled:focus {
    color: var(--gv-text-btn-disabled-color);
}
.ant-btn-text svg > * {
    fill: currentColor;
}

/* input */
.ant-input::placeholder, .ant-input-password::placeholder, .ant-input-number-input::placeholder, .ant-input-number .ant-input-number-input::placeholder {
    color: var(--gv-input-placeholder-color);
}
.ant-input, .ant-input-password, .ant-input-affix-wrapper, .ant-input-number {
    border-radius: var(--gv-input-border-radius);
    background-color: var(--gv-input-bg);
    color: var(--gv-body-color);
    border-color: var(--gv-input-border-color);
}
.ant-input-number .ant-input-number-input {
    color: var(--gv-body-color);
    background-color: var(--gv-input-bg);
}
.ant-input-outlined:focus, .ant-input-outlined:focus-within {
    background-color: var(--gv-input-hover-bg);
}
.ant-input-outlined.ant-input-disabled, .ant-input-outlined[disabled] {
    color: var(--gv-input-disabled-color);
    background-color: var(--gv-input-disabled-bg);
    border-color: var(--gv-input-border-color);
}
.ant-input-outlined.ant-input-disabled:hover:not([disabled]), .ant-input-outlined[disabled]:hover:not([disabled]) {
    background-color: var(--gv-input-disabled-bg);
    border-color: var(--gv-input-border-color);
}

.ant-input-number-outlined.ant-input-number-disabled, .ant-input-number-outlined[disabled] {
    color: var(--gv-input-disabled-color);
    background-color: var(--gv-input-disabled-bg);
    border-color: var(--gv-input-border-color);
}
.ant-input-number-outlined.ant-input-number-disabled input[disabled], .ant-input-number-outlined[disabled] input[disabled], .ant-input-number-outlined.ant-input-number-disabled textarea[disabled], .ant-input-number-outlined[disabled] textarea[disabled] {
    color: var(--gv-input-disabled-color);
    background-color: var(--gv-input-disabled-bg);
}
.ant-input-number-outlined.ant-input-number-disabled:hover:not([disabled]), :where(.css-dev-only-do-not-override-77tu7h).ant-input-number-outlined[disabled]:hover:not([disabled]) {
    background-color: var(--gv-input-disabled-bg);
    border-color: var(--gv-input-border-color);
}
.ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner, .ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
    color: var(--gv-body-color);
}
.ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler {
    border-color: var(--gv-input-hover-border-color);
}

.ant-input-number-group-wrapper-outlined .ant-input-number-group-addon {
    color: var(--gv-input-color);
    background-color: var(--gv-input-disabled-bg);
    border-color: var(--gv-input-border-color);
    border-radius: var(--gv-input-border-radius);
}
.ant-input-number-group-wrapper-outlined.ant-input-number-group-wrapper-disabled .ant-input-number-group-addon,
.ant-input-number-group-wrapper-outlined.ant-input-number-group-wrapper-disabled .ant-input-number-group-addon:hover:not([disabled]) {
    color: var(--gv-input-color);
    background-color: var(--gv-input-disabled-bg);
    border-color: var(--gv-input-border-color);
}
/* checkbox */
.ant-checkbox-wrapper {
    color: var(--gv-body-color);
}

.ant-checkbox .ant-checkbox-inner,
.ant-tree .ant-tree-checkbox .ant-tree-checkbox-inner,
.ant-tree .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner,
.ant-tree-select-dropdown .ant-select-tree-checkbox .ant-select-tree-checkbox-inner {
    background-color: var(--gv-checkbox-bg);
    border-color: var(--gv-checkbox-border);
    border-radius: var(--gv-checkbox-border-radius);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner,
.ant-tree-select-dropdown .ant-select-tree-checkbox-wrapper:not(.ant-select-tree-checkbox-wrapper-disabled):hover .ant-select-tree-checkbox-inner,
.ant-tree-select-dropdown .ant-select-tree-checkbox:not(.ant-select-tree-checkbox-disabled):hover .ant-select-tree-checkbox-inner {
    background-color: var(--gv-checkbox-hover-bg);
    border-color: var(--gv-checkbox-hover-border);
}
.ant-checkbox-checked .ant-checkbox-inner,
.ant-tree .ant-tree-checkbox-checked .ant-tree-checkbox-inner,
.ant-tree-select-dropdown .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    background-color: var(--gv-checkbox-checked-bg);
    border-color: var(--gv-checkbox-checked-border);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner,
.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, .ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner,
.ant-tree-select-dropdown .ant-select-tree-checkbox-wrapper-checked:not(.ant-select-tree-checkbox-wrapper-disabled):hover .ant-select-tree-checkbox-inner,
.ant-tree-select-dropdown .ant-select-tree-checkbox-checked:not(.ant-select-tree-checkbox-disabled):hover .ant-select-tree-checkbox-inner {
    background-color: var(--gv-checkbox-checked-hover-bg);
    border-color: var(--gv-checkbox-checked-hover-border);
}

.ant-tree .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
    border-color: var(--gv-input-disabled-color);
}
.ant-checkbox-disabled .ant-checkbox-inner:after {
    border-color: var(--gv-input-disabled-color);
}

/* select */
.ant-select {
    color: var(--gv-input-color);
}
.ant-select-single.ant-select-sm .ant-select-selector {
    color: var(--gv-input-color);
}
.ant-select .ant-select-arrow {
    color: var(--gv-input-color);
}
.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border-color: var(--gv-input-border-color);
    background: var(--gv-input-bg);
}
.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    background: var(--gv-input-hover-bg);
    border-color: var(--gv-input-hover-border-color);
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    color: var(--gv-input-color);
}
.ant-select-single.ant-select-open .ant-select-selection-item,
.ant-select-single.ant-select-sm.ant-select-open .ant-select-selection-item {
    color: var(--gv-input-color);
    opacity: .5;
}
.ant-tree-select-dropdown .ant-select-tree .ant-select-tree-treenode:not(.ant-select-tree-treenode-disabled) .ant-select-tree-node-content-wrapper:hover {
    color: var(--gv-list-item-color);
    background-color: var(--gv-list-item-hover-bg);
}
.ant-tree-select-dropdown .ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
    color: var(--gv-list-item-disabled-color);
}
.ant-tree-select-dropdown .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
    color: var(--gv-list-item-selected-color);
    background-color: var(--gv-list-item-selected-bg);
}

/* dropdown */
.ant-dropdown {
    color: var(--gv-dropdown-menu-color);
}
.ant-dropdown .ant-dropdown-arrow:before {
    background-color: var(--gv-dropdown-menu-bg);
}
.ant-dropdown .ant-dropdown-menu, .ant-dropdown-menu-submenu .ant-dropdown-menu {
    border: var(--gv-dropdown-menu-border);
    border-radius: var(--gv-dropdown-menu-border-radius);
    background-color: var(--gv-dropdown-menu-bg);
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item, .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item, .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title, .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
    color: var(--gv-dropdown-menu-color);
    border-radius: var(--gv-dropdown-menu-item-border-radius);
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:hover, .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover, .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover {
    background-color: var(--gv-dropdown-menu-item-hover-bg);
    color: var(--gv-dropdown-menu-item-hover-color);
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active, .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-active, .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active, .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active {
    background-color: var(--gv-dropdown-menu-item-selected-bg);
    color: var(--gv-dropdown-menu-item-selected-color);
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-disabled, .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-disabled, .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-disabled, .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title-disabled {
    color: var(--gv-dropdown-menu-item-disabled-color);
    background-color: var(--gv-dropdown-menu-bg);
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-disabled:hover, .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-disabled:hover, .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-disabled:hover, .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title-disabled:hover {
    color: var(--gv-dropdown-menu-item-disabled-color);
    background-color: var(--gv-dropdown-menu-bg);
}

/* popover */
.ant-popover {
    color: var(--gv-dropdown-menu-color);
    background-color: var(--gv-dropdown-menu-bg);
    border: var(--gv-dropdown-menu-border);
    border-radius: var(--gv-dropdown-menu-border-radius);
}
.ant-popover .ant-popover-arrow:before {
    background-color: var(--gv-dropdown-menu-bg);
}
.ant-popover .ant-popover-inner {
    border: var(--gv-dropdown-menu-border);
    border-radius: var(--gv-dropdown-menu-border-radius);
    background-color: var(--gv-dropdown-menu-bg);
}
.ant-popover .ant-popover-title {
    color: var(--gv-dropdown-menu-color);
    border-color: var(--gv-dropdown-menu-border-color);
}
.ant-popover .ant-popover-inner-content {
    color: var(--gv-dropdown-menu-color);
}
.ant-popover-placement-bottom .ant-popover-arrow {
    box-shadow: 0 1px var(--gv-dropdown-menu-bg);
}
.ant-popover-arrow-content:before {
    background-color: var(--gv-dropdown-menu-bg);
}

/* switch */
.ant-switch {
    color: var(--gv-switch-color);
    background-color: var(--gv-switch-bg);
}
.ant-switch:hover:not(.ant-switch-disabled) {
    background-color: var(--gv-switch-bg);
}
.ant-switch.ant-switch-checked {
    background-color: var(--gv-switch-checked-bg);
}
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background-color: var(--gv-switch-checked-bg);
}
.ant-switch .ant-switch-handle::before {
    background-color: var(--gv-switch-handle-bg);
}

/* radio */
.ant-radio-group, .ant-radio-wrapper {
    color: var(--gv-text-btn-color);
}
.ant-radio-wrapper .ant-radio {
    color: var(--gv-input-color);
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: var(--gv-checkbox-checked-border);
    background-color: var(--gv-checkbox-checked-bg);
}
.ant-radio-wrapper .ant-radio-disabled .ant-radio-inner {
    background-color: var(--gv-input-disabled-bg);
    border-color: var(--gv-input-disabled-color);
}
.ant-radio-wrapper .ant-radio-disabled .ant-radio-inner::after {
    background-color: var(--gv-checkbox-checked-bg);
}


.ant-radio-button-wrapper {
    color: var(--gv-btn-secondary-color);
    background-color: var(--gv-btn-secondary-bg);
    border-color: var(--gv-btn-secondary-border-color);
}
.ant-radio-button-wrapper:not(:first-child)::before {
    background-color: var(--gv-btn-secondary-border-color);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: var(--gv-btn-primary-color);
    background-color: var(--gv-btn-primary-bg);
    border-color:  var(--gv-btn-primary-bg);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: var(--gv-btn-primary-color);
    background-color: var(--gv-btn-primary-hover-bg);
    border-color:  var(--gv-btn-primary-hover-bg);
}
.ant-radio-button-wrapper-disabled {
    color: var(--gv-btn-secondary-disabled-color);
    background-color: var(--gv-btn-secondary-disabled-bg);
    border-color: var(--gv-btn-secondary-disabled-border-color);
}
.ant-radio-button-wrapper-disabled:not(:first-child)::before {
    background-color: var(--gv-btn-secondary-disabled-border-color);
}
.ant-radio-button-wrapper:first-child {
    border-inline-color: var(--gv-btn-secondary-border-color);
}
.ant-radio-button-wrapper-disabled:first-child, .ant-radio-button-wrapper-disabled:hover {
    color: var(--gv-btn-secondary-disabled-color);
    border-inline-color: var(--gv-btn-secondary-disabled-border-color);
    border-color: var(--gv-btn-secondary-disabled-border-color);
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
    color: var(--gv-btn-primary-disabled-color);
    background-color: var(--gv-btn-primary-disabled-bg);
    border-color:  var(--gv-btn-primary-disabled-bg);
}

/* segmented */
.ant-segmented {
    border-radius: var(--gv-segmented-border-radius);
}
.ant-segmented, .ant-segmented:not(.ant-segmented-disabled):focus, .ant-segmented:not(.ant-segmented-disabled):hover {
    color: var(--gv-segmented-color);
    background-color: var(--gv-segmented-bg);
}
.ant-segmented .ant-segmented-item:hover:not(.ant-segmented-item-selected):not(.ant-segmented-item-disabled) {
    color: var(--gv-segmented-item-hover-color);
    background-color: var(--gv-segmented-item-hover-bg);
}
.ant-segmented .ant-segmented-thumb ~ .ant-segmented-item:hover:not(.ant-segmented-item-selected):not(.ant-segmented-item-disabled) {
    color: unset;
    background-color: unset;
}
.ant-segmented .ant-segmented-item-disabled, .ant-segmented .ant-segmented-item-disabled:hover, .ant-segmented .ant-segmented-item-disabled:focus {
    color: var(--gv-segmented-item-disabled-color);
}
.ant-segmented .ant-segmented-item-selected, .ant-segmented .ant-segmented-thumb-motion {
    border-radius: var(--gv-segmented-item-border-radius);
}
.ant-segmented .ant-segmented-item-selected, .ant-segmented .ant-segmented-thumb-motion-appear-active, .ant-segmented .ant-segmented-thumb-motion,
.ant-segmented .ant-segmented-item-selected:hover {
    color: var(--gv-segmented-item-selected-color);
    background-color: var(--gv-segmented-item-selected-bg);
}
.ant-segmented .ant-segmented-item:focus:not(.ant-segmented-item-selected):not(.ant-segmented-item-disabled) {
    color: var(--gv-segmented-item-active-color);
    background-color: var(--gv-segmented-item-active-bg);
}
.ant-segmented-item-icon svg path {
    fill: currentColor;
}

.and-segmented-ex .ant-segmented-item-label.activing {
    color: var(--gv-segmented-item-selected-color);
    transition: color .3s cubic-bezier(.645,.045,.355,1);
}
.and-segmented-ex .ant-segmented-item.ant-segmented-item-selected {
    transition: none;
}

.ant-select-tree-list-scrollbar, .ant-tree-list-scrollbar, .rc-virtual-list-scrollbar {
    width: var(--gv-scrollbar-width) !important;
}
.ant-select-tree-list-scrollbar-thumb, .ant-tree-list-scrollbar-thumb, .rc-virtual-list-scrollbar-thumb {
    background-color: var(--gv-scrollbar-bg) !important;
    border-radius: var(--gv-scrollbar-border-radius) !important;
}

.ant-message .ant-message-notice .ant-message-notice-content {
    color: var(--gv-body-color);
    background-color: var(--gv-Announcement-bg);
    box-shadow: var(--gv-Announcement-box-shadow);
    border-radius: var(--gv-Announcement-border-radius);
    border: var(--gv-Announcement-border);
}

.ant-empty .ant-empty-description {
    color: var(--gv-body-color);
}

.ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: '';
    margin: 0;
}
.ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
    font-weight: 700;
}

/* modal */
.ant-modal {
    color: var(--gv-body-color);
    border: var(--gv-modal-border);
    border-radius: var(--gv-modal-border-radius);
}
.ant-modal .ant-modal-content {
    border-radius: var(--gv-modal-border-radius);
    background-color: var(--gv-modal-bg);
}
.ant-modal .ant-modal-header {
    color: var(--gv-body-color);
    background-color: var(--gv-modal-bg);
    border-radius: var(--gv-modal-border-radius) var(--gv-modal-border-radius) 0 0;
}
.ant-modal .ant-modal-title {
    color: var(--gv-body-color);
}
.dark .ant-modal-mask {
    background-color: rgba(255, 255, 255, .15);
}
.ant-modal .ant-modal-close {
    color: var(--gv-modal-close-color);
}
.ant-modal .ant-modal-close:hover {
    color: var(--gv-modal-close-hover-color);
}
.ant-modal .ant-modal-close:active {
    color: var(--gv-modal-close-active-color);
}

/* tooltip */
.ant-tooltip {
    color: var(--gv-tooltip-color);
}
.ant-tooltip .ant-tooltip-arrow:before {
    background-color: var(--gv-tooltip-bg);
}
.ant-tooltip .ant-tooltip-inner {
    background-color: var(--gv-tooltip-bg);
    color: var(--gv-tooltip-color);
    border-radius: var(--gv-tooltip-border-radius);
}


/* list */
.ant-list .ant-list-item {
    color: var(--gv-list-item-color);
    background-color: var(--gv-list-item-bg);
    border-color: var(--gv-list-item-border-color);
    transition: background-color .3s;
}
.ant-list .ant-list-item:hover {
    background-color: var(--gv-list-item-hover-bg);
    border-color: var(--gv-list-item-hover-bg);
}
.ant-list .ant-list-item:active {
    background-color: var(--gv-list-item-selected-bg);
    border-color: var(--gv-list-item-selected-bg);
    color: var(--gv-list-item-selected-color);
}
.ant-list .ant-list-item.disabled, .ant-list .ant-list-item:disabled {
    color: var(--gv-list-item-disabled-color);
}
.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-content,
.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-title {
    color: var(--gv-list-item-color);
}
.ant-list .ant-list-item.disabled .ant-list-item-meta .ant-list-item-meta-content,
.ant-list .ant-list-item.disabled .ant-list-item-meta .ant-list-item-meta-title {
    color: var(--gv-list-item-disabled-color);
}

.ant-divider {
    color: var(--gv-divider-color);
    border-block-color: var(--gv-divider-color);
}

/* collapse */
.ant-collapse {
    color: var(--gv-body-color);
}
.ant-collapse>.ant-collapse-item >.ant-collapse-header {
    color: var(--gv-body-color);
}
.ant-collapse>.ant-collapse-item >.ant-collapse-header >.ant-collapse-header-text {
    text-align: left;
}

/* color-picker */
.ant-color-picker-trigger {
    background-color: var(--gv-input-bg);
    border-color: var(--gv-input-border-color);
    padding: 4px;
}
.ant-color-picker-trigger:hover {
    background-color: var(--gv-input-hover-bg);
    border-color: var(--gv-input-hover-border-color);
}
.ant-color-picker .ant-color-picker-inner .ant-color-picker-clear {
    border-color: var(--gv-input-border-color);
}
.ant-color-picker .ant-color-picker-inner .ant-color-picker-clear:hover {
    border-color: var(--gv-input-hover-border-color);
}
.ant-color-picker .ant-color-picker-inner .ant-color-picker-input-container .ant-color-picker-input .ant-color-picker-hex-input.ant-input-affix-wrapper .ant-input-prefix {
    color: var(--gv-input-color);
}
.ant-color-picker .ant-color-picker-inner .ant-color-picker-presets .ant-collapse-item>.ant-collapse-header .ant-collapse-expand-icon {
    color: var(--gv-body-color);
}
.ant-color-picker .ant-color-picker-inner .ant-color-picker-presets-label {
    color: var(--gv-body-color);
}
.dark .ant-color-picker-trigger .ant-color-picker-color-block,
.dark .ant-color-picker .ant-color-picker-inner .ant-color-picker-color-block,
.dark .ant-color-picker .ant-color-picker-inner .ant-color-picker-slider {
    background-image: conic-gradient(rgba(255, 255, 255, 0.4) 0 25%, transparent 0 50%, rgba(255, 255, 255, 0.4) 0 75%, transparent 0);
}

/* tabs */
.ant-tabs {
    color: var(--gv-tab-color);
}
.ant-tabs .ant-tabs-tab {
    color: var(--gv-tab-color);
}
.ant-tabs .ant-tabs-tab:hover {
    color: var(--gv-tab-hover-color);
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--gv-tab-selected-color);
}
.ant-tabs .ant-tabs-ink-bar {
    background: var(--gv-tab-selected-color);
}

/* pagination */
.ant-pagination {
    color: var(--gv-text-btn-color);

    --pagination-hover-font-color: #fff;
    --pagination-disabled-color: rgba(0, 0, 0, 0.25);
}
.dark .ant-pagination {
    --pagination-disabled-color: rgba(255, 255, 255, 0.25);
}
.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-prev .ant-pagination-item-link, .ant-pagination .ant-pagination-next .ant-pagination-item-link,
.ant-pagination .ant-pagination-prev, .ant-pagination .ant-pagination-next, .ant-pagination .ant-pagination-jump-prev, .ant-pagination .ant-pagination-jump-next {
    border-radius: 50%;
}


.ant-pagination .ant-pagination-prev, .ant-pagination .ant-pagination-next, .ant-pagination .ant-pagination-jump-prev, .ant-pagination .ant-pagination-jump-next,
.ant-pagination .ant-pagination-prev button, .ant-pagination .ant-pagination-next button,
.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: var(--gv-body-color);
    background-color: transparent;
}
.ant-pagination .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination .ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination .ant-pagination-jump-prev:hover, .ant-pagination .ant-pagination-jump-next:hover,
.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: var(--pagination-hover-font-color);
    background-color: var(--gv-btn-primary-hover-bg);
}
.ant-pagination .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination .ant-pagination-disabled:hover .ant-pagination-item-link {
    color: var(--pagination-disabled-color);
}
.ant-pagination .ant-pagination-item {
    color: var(--gv-text-btn-color);
    background-color: var(--gv-text-btn-bg);
    transition: none;
}
.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover {
    color: var(--pagination-hover-font-color);
    background-color: var(--gv-btn-primary-hover-bg);
    transition: none;
}
.ant-pagination .ant-pagination-item-active {
    color: var(--pagination-hover-font-color);
    background-color: var(--gv-btn-primary-bg);
}