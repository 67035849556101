.usersetting-org-fields {
    position: relative;
}
.usersetting-org-fields .ant-form-item-label > label {
    display: flex;
    align-items: center;
    grid-gap: 0.5em;
}


.organization-title-card .card-body {
    padding-bottom: 0;
}
.organization-title-form .ant-form-item-label {
    padding-bottom: 4px;
}
.organization-title-form .ant-form-item-label > label {
    display: flex;
    align-items: center;
    grid-gap: 0.5em;
}

.organization-tree .ant-tree-list-holder {
    min-height: var(--org-tree-height);
}

.organization-tree-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.organization-toolbar {
    display: flex;
    grid-gap: 0.5em;
}
.organization-toolbar button {
    min-width: 60px;
    display: flex;
    grid-gap: 0.3em;
    align-items: center;
}
.organization-toolbar button svg {
    width: 16px;
    height: 16px;
}
.organization-toolbar button svg > * {
    fill: #fff;
}

.organization-tree .ant-tree-node-content-wrapper .ant-tree-drop-indicator {
    display: none;
}

.organization-tree-title {
    padding: 0 5px;
    border: 2px solid transparent;
    border-radius: 5px;
    display: flex;
    align-items: center;
    grid-gap: 0.5em;
}
.organization-tree-title.drop-node {
    border-color: #d9d9d9;
}

.organization-tree .ant-tree-indent-unit {
    width: 30px;
}



