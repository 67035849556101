.daterange-popover .ant-popover-inner {
    padding: 4px;
}
.daterange-popover .ant-popover-inner-content {
    padding: 0;
}

.daterange-input-group .ant-input-clear-icon {
    display: flex;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s;
}
.dark .daterange-input-group .ant-input-clear-icon {
    color: var(--gv-body-color);
    background-color: var(--gv-input-bg);
}
.daterange-input-group:hover .ant-input-clear-icon {
    opacity: 1;
}

.daterange-dropdown-menu {
    --daterange-menu-height: 250px;
    --daterange-header-height: 37px;
    --daterange-time-cell-height: 28px;
    --daterange-border-color: #E2E6ED;

    --daterange-week-color: #88a9b3;

    --daterange-cell-border-radius: 15px;
    --daterange-cell-diff-month-color: #aaa;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    height: var(--daterange-menu-height);
    color: var(--gv-body-color);
    font-size: 14px;
}
.dark .daterange-dropdown-menu {
    --daterange-border-color: #313336;

    --daterange-week-color: #d9d9d9;

    --daterange-cell-diff-month-color: rgba(217, 217, 217, 0.4);
}
.daterange-dropdown-menu.with-range {
    --daterange-menu-height: 285px;
}

.range-picker, .date-picker, .year-picker {
    border-right: 1px solid var(--daterange-border-color);
}

.daterange-picker-panel {
    overflow: hidden;
}
.daterange-picker-main-panel, .daterange-picker-sub-panel {
    display: flex;
    transition: 0.3s all;
}
.daterange-picker-main-panel {
    transform-origin: top;
}
.daterange-picker-sub-panel {
    transform: scaleY(0);
    height: 0;
    transform-origin: bottom;
}

.daterange-picker-main-panel.hide {
    transform: scaleY(0);
    height: 0;
}
.daterange-picker-sub-panel.show {
    transform: scaleY(1);
    height: initial;
}

/* Range Picker */
.range-picker {
    padding: 0.2em;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
}
.range-picker > * {
    cursor: pointer;
    padding: 0.3em 0.5em;
    margin: 0 0.2em;
    color: var(--gv-list-item-color);
    border-radius: var(--gv-btn-border-radius);
}
.range-picker > *:hover {
    background-color: var(--gv-list-item-hover-bg);
}
.range-picker > *:focus, .range-picker > *:active {
    background-color: var(--gv-list-item-selected-bg);
    color: var(--gv-list-item-selected-color);
    border-radius: 6px;
}
.range-picker > .separator {
    padding: 0;
    margin: 0.2em;
    height: 1px;
    border-bottom: 1px solid var(--daterange-border-color);
    cursor: default;
}

/* Date picker */
.date-picker {

    min-width: 240px;
}

.date-picker-control {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--daterange-border-color);
    padding: 0.5em;
    margin-bottom: 0.5em;
}

.date-picker-title {
    font-weight: bold;
    cursor: pointer;
    flex: 1 1 auto;
    text-align: center;
}

.date-picker-control-switch {
    cursor: pointer;
    width: 24px;
    text-align: center;
    border: none;
    background-color: transparent;
    padding: 0;
    position: relative;
    color: var(--gv-list-item-color);
    border-radius: var(--gv-btn-border-radius);
}
.date-picker-control-switch:hover {
    background-color: var(--gv-list-item-hover-bg);
}
.date-picker-control-switch:active {
    color: var(--gv-list-item-selected-color);
    background-color: var(--gv-list-item-selected-bg);
}
.date-picker-control svg {
    width: 18px;
    height: 18px;
}


.date-picker-weeks {
    display: flex;
}

.date-picker-weeks > * {
    font-size: 9px;
    font-weight: 400;
    line-height: 2.667em;
    color: var(--daterange-week-color);
    flex-basis: calc(100% / 7);
    box-sizing: inherit;
    text-align: center;
}

.date-picker-days {
    display: flex;
    flex-wrap: wrap;
}
.date-picker-day-cell {
    background: transparent;
    border: 0;
    padding: 0;
    height: 30px;
    line-height: 30px;
    text-align: center;
    box-sizing: inherit;
    width: calc(100% / 7);
    position: relative;
    cursor: pointer;
    color: var(--gv-list-item-color);
    border-radius: var(--daterange-cell-border-radius);
}
.date-picker-day-cell.diff-month {
    color: var(--daterange-cell-diff-month-color);
}
.date-picker-day-cell:hover {
    background-color: var(--gv-list-item-hover-bg);
}
.date-picker-day-cell.selected {
    background-color: var(--gv-list-item-selected-bg);
    color: var(--gv-list-item-selected-color);
}
.date-picker-day-cell.disabled {
    color: var(--gv-list-item-disabled-color);
    pointer-events: none;
}

/* Time Picker */
.time-picker {
    display: flex;
    height: var(--daterange-menu-height);
    position: relative;
}
.time-picker::after {
    content: '';
    width: 100%;
    height: var(--daterange-time-cell-height);
    position: absolute;
    background-color: var(--gv-list-item-selected-bg);
    border-radius: var(--daterange-cell-border-radius);
    
    top: calc((100% - var(--daterange-header-height)) / 2 + var(--daterange-header-height));
    transform: translateY(-50%);
}

.time-picker-column {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    text-align: center;
    width: 52px;
    min-width: 52px;
}
.time-picker-column:not(:last-child) {
    border-right: 1px solid var(--daterange-border-color);
}

.time-picker-header {
    flex: 0 0 auto;
    font-weight: bold;
    border-bottom: 1px solid var(--daterange-border-color);
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.time-picker-header > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 0.2rem;
}
.time-picker-panel {
    flex: 1 1 auto;
    z-index: 1;
    padding: calc((var(--daterange-menu-height) - var(--daterange-header-height) - var(--daterange-time-cell-height)) / 2) 0;
    overflow: hidden;
}
.time-picker-panel::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}
.time-picker-panel > * {
    cursor: pointer;
    line-height: var(--daterange-time-cell-height);
    border-radius: var(--daterange-cell-border-radius);
}
.time-picker-panel > *:hover {
    background-color: var(--gv-list-item-hover-bg);
}
.time-picker-panel > *.selected {
    color: var(--gv-list-item-selected-color);
    pointer-events: none;
}
.time-picker-panel > *.disabled {
    color: var(--gv-list-item-disabled-color);
    pointer-events: none;
}

/* Year Picker */
.year-picker {
    height: var(--daterange-menu-height);
    min-width: 240px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.year-picker > * {
    padding: 0.6em;
    cursor: pointer;
    color: var(--gv-list-item-color);
    border-radius: var(--gv-input-border-radius);
}
.year-picker > *:hover {
    background-color: var(--gv-list-item-hover-bg);
}
.year-picker > *.selected {
    background-color: var(--gv-list-item-selected-bg);
    color: var(--gv-list-item-selected-color);
}
.year-picker > *.disabled {
    color: var(--gv-list-item-disabled-color);
    pointer-events: none;
}

/* Month Picker */
.month-picker {
    display: flex;
    white-space: nowrap;
    width: 150px;
    min-width: 150px;
    justify-content: space-around;
}
.month-picker-column {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.month-picker-column > * {
    padding: 0.3em 0.6em;
    cursor: pointer;
    color: var(--gv-list-item-color);
    border-radius: var(--gv-input-border-radius);
}
.month-picker-column > *:hover {
    background-color: var(--gv-list-item-hover-bg);
}
.month-picker-column > *.selected {
    background-color: var(--gv-list-item-selected-bg);
    color: var(--gv-list-item-selected-color);
}
.month-picker-column > *.disabled {
    color: var(--gv-list-item-disabled-color);
    pointer-events: none;
}