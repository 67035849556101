.import-photo-modal > .ant-modal-content > .ant-modal-body {
    height: 60vh;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    padding: 1rem 2rem;
    overflow: hidden;
}

.import-photo-modal .ant-modal-footer {
    align-items: center;
}
.import-photo-modal .ant-modal-footer .upload-progress {
    flex: 1 1 auto;
    margin: 0 1em;
    display: flex;
    align-items: center;
    grid-gap: 0.5em;
}
.import-photo-modal .ant-modal-footer .upload-progress .upload-progress-bar {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}
.import-photo-modal .ant-modal-footer .upload-progress .upload-progress-bar .progress {
    flex: 1 1 auto;
}
.import-photo-modal .ant-modal-footer .upload-progress .upload-progress-bar span {
    text-align: center;
}
.import-photo-modal .ant-modal-footer .upload-progress button {
    border-radius: var(--gv-btn-border-radius);
    padding: 0 0.5rem;
    min-width: unset;
}

.import-photo-field {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
}
.import-photo-field .as-ant-select {
    flex: 1 1 auto;
}

.import-photo-card {
    flex: 1 1 auto;
    overflow: hidden;
}
.import-photo-card .card-header {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
}
.import-photo-card .card-header > .ant-btn {
    display: flex;
    align-items: center;
    grid-gap: 0.25rem;
}
.import-photo-card .card-header > .ant-btn svg {
    width: 12px;
    height: 12px;
    color: currentColor;
}
.import-photo-card .card-header > .space {
    flex: 1 1 auto;
}
.import-photo-card .card-header .ant-slider .ant-slider-rail {
    background-color: var(--gv-btn-primary-disabled-bg);
}



.import-photo-card .card-body {
    padding: 0;
    display: flex;
    overflow: hidden;
}
.import-photo-file-drop {
    flex: 1 1 auto;
    overflow: hidden;

    --import-photo-drop-shadow-color: 0, 0, 0;
}
.dark .import-photo-file-drop {
    --import-photo-drop-shadow-color: 255, 255, 255;
}
.import-photo-file-drop-target {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    position: relative;
}
.import-photo-file-drop-target.file-drop-dragging-over-frame {
    box-shadow: inset 0 0 6px rgba(var(--import-photo-drop-shadow-color),.3);
}
.import-photo-file-drop-target.file-drop-dragging-over-target {
    box-shadow: inset 0 0 8px rgba(var(--import-photo-drop-shadow-color),.6);
}
.import-photo-file-drop-tip {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: .8;
}


.import-photo-file-list {
    max-height: 100%;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    grid-gap: 1rem;

    --import-photo-size: 56px;
    --import-photo-item-bg: #eee;
    --import-photo-item-hover-bg: #e6e6e6;
}
.dark .import-photo-file-list {
    --import-photo-item-bg: #313336;
    --import-photo-item-hover-bg: #4c4d4f;
}
.import-photo-file-item {
    background-color: var(--import-photo-item-bg);
    padding: 6px;
    border-radius: 10px;

    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    position: relative;
}
.import-photo-file-item:hover {
    background-color: var(--import-photo-item-hover-bg);
}
.import-photo-file-item.active {
    background-color: var(--gv-list-item-selected-bg);
}

.import-photo-file-item .gv-user-avatar {
    width: var(--import-photo-size);
    height: var(--import-photo-size);
    display: flex;
    align-items: center;
    justify-content: center;
}
.import-photo-file-item .gv-user-avatar.loading {
    background-image: none;
}
.import-photo-file-item-buttons {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    display: flex;
    align-items: center;
    grid-gap: 0.1rem;
    transition: opacity 0.3s ease-in;
    opacity: 0;
}
.import-photo-file-item:hover .import-photo-file-item-buttons {
    opacity: 1;
}
.import-photo-file-item-buttons svg {
    cursor: pointer;
    width: 16px;
    height: 16px;
}
.import-photo-file-item-buttons svg.edit {
    width: 20px;
    height: 20px;
}
.import-photo-file-item-fields {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.import-photo-file-item-fields > .user-name {
    font-size: calc(var(--gv-body-font-size) - 2px);
    opacity: .8;
}
.import-photo-file-item-fields > .error-msg {
    font-size: calc(var(--gv-body-font-size) - 2px);
    color: #ff4d4f;
}

.import-photo-file-item-status {
    position: absolute;
    top: -0.2rem;
    left: 0;
}
.import-photo-file-item-status .success {
    color: #008000;
}
.import-photo-file-item-status .fail {
    color: #f00;
}


.edit-photo-modal .ant-modal-footer .ant-btn-circle {
    padding: 0;
    min-width: auto;
}
.edit-photo-cropper {
    height: 600px;
    max-height: calc(100vh - 240px);
    padding: 0.5rem;
}