.common-alert-body {
    display: flex;
    align-items: center;
    overflow: auto;
}
.common-alert-body > span {
    white-space: pre-line;
}

.commom-msg-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px 0 10px;
}

.commom-msg-icon.warning {
    color: #ffa500;
}
.commom-msg-icon.success {
    color: #9acd32;
}
.commom-msg-icon.error {
    color: #ff0000;
}

.commom-msg-icon.question {
    color: #EF3C3C;
}
.commom-msg-icon.info {
    color: var(--gv-btn-primary-bg);
}

.commom-msg-icon>svg {
    width: 36px;
    height: 36px;
}

.common-alert-form {
    margin-top: 2em;
}
.common-alert-form .ant-form-item {
    margin-bottom: 0;
}

.common-update-device-modal .ant-modal-body {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
}
.common-release-note-card .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.common-release-note-card .card-body {
    position: relative;
    min-height: 120px;
    max-height: 50vh;
}
.common-release-note-card .card-body h3 {
    font-size: 1.25rem;
}

.common-mask {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(var(--gv-body-bg-rgb), 0.4);
    border-radius: var(--ascloud-card-border-radius);
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    flex: 1 1 auto;
}
.common-mask.transparent {
    background-color: transparent;
}
.common-mask.hide {
    display: none;
}
.common-mask span {
    font-size: 18px;
    font-weight: bold;
}

.common-mask>button {
    width: 100px;
    margin:5px auto;
}

:not(.ant-select-item-empty):not(.ant-select-empty) > .common-no-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
}
.ant-select-item-empty .common-no-data,
.ant-select-empty .common-no-data {
    color: rgba(0,0,0,.25);
}
.dark .ant-select-item-empty .common-no-data,
.dark .ant-select-empty .common-no-data {
    color: rgba(255,255,255,.45);
}
.ant-select-item-empty .common-no-data .ant-empty-image,
.ant-select-empty .common-no-data .ant-empty-image  {
    height: 64px;
}
.common-no-data .ant-empty-image svg {
    width: 100%;
}

.common-plate-no-photo {
    overflow: hidden;
    display: none;
    position: absolute;
    border-radius: 6px;
    box-shadow: 0 4px 10px 0 rgb(0 0 0);
    border: 2px solid #fff;
    transition: opacity .3s;
}

.common-plate-no-text {
    position: absolute;
    /* display: none; */
    border: 2px solid rgb(0 0 0 / 35%);
    box-shadow: 0 3px 6px rgb(0 0 0 / 15%);
    border-radius: 6px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    background-color: #F4F7FA77;
    font-family: monospace;
    font-stretch: ultra-condensed;
}
.common-plate-no-text > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(.95);
}


.time-range-field {
    display: flex;
    align-items: center;
}
.time-range-field input {
    flex: 1 1 auto;
}
.time-range-field::after {
    content: attr(attr-data-memo);
    pointer-events: none;
    position: absolute;
    left: 100px;
    color: var(--gv-input-disabled-color);
}

.time-range-picker {
    --timerange-panel-height: 140px;
    --timerange-cell-height: 28px;
    --timerange-cell-border-radius: 14px;

    display: flex;
    align-items: center;
    height: var(--timerange-panel-height);
    width: 160px;
    overflow: hidden;
    grid-gap: 0.2em;
    position: relative;
    z-index: 0;
}

.time-range-picker::after {
    content: '';
    width: 100%;
    height: var(--timerange-cell-height);
    position: absolute;
    background-color: var(--gv-list-item-selected-bg);
    border-radius: var(--timerange-cell-border-radius);
    z-index: -1;
}

.time-range-picker span {
    color: var(--gv-list-item-selected-color);
}

.time-range-picker-column {
    height: 100%;
    text-align: center;
    width: calc(100% / 4 + 0.2em);
    padding: calc((var(--timerange-panel-height) - var(--timerange-cell-height)) / 2) 0;
    overflow: hidden;
}
.time-range-picker-column::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}
.time-range-picker-column > * {
    cursor: pointer;
    line-height: var(--timerange-cell-height);
    border-radius: var(--timerange-cell-border-radius);
    color: var(--gv-list-item-color);
}
.time-range-picker-column > *:hover {
    background-color: var(--gv-list-item-hover-bg);
}
.time-range-picker-column  > *.selected {
    pointer-events: none;
    background-color: transparent;
    color: var(--gv-list-item-selected-color);
}
.time-range-picker-column  > *.disabled {
    pointer-events: none;
    color: var(--gv-list-item-disabled-color);
}


.common-region-collapse {
    font-family: var(--gv-body-font-family);
    background-color: transparent;
}
.common-region-collapse .ant-collapse-item {
    border-bottom-width: 0;
}
.common-region-collapse.ant-collapse > .ant-collapse-item >.ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
}
.common-region-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0.5rem;
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 3;
    color: var(--gv-body-color);
    background-color: var(--ascloud-component-bg);
}
.common-region-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
    text-align: left;
    display: flex;
    align-items: center;
    grid-gap: 0.5em;
    overflow: hidden;
}
.common-region-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text > svg {
    flex: 0 0 auto;
}
.common-region-collapse.ant-collapse > .ant-collapse-item .ant-collapse-expand-icon svg.ant-collapse-arrow {
    vertical-align: middle;
    font-weight: bold;
}
.common-region-collapse.ant-collapse > .ant-collapse-item .ant-collapse-content-box {
    padding: 0 !important;
}

.icon-arrow-drop-down {
    transform: rotate(-90deg);
    transition: all 0.3s;
    width: 10px;
    height: 10px;
}
.icon-arrow-drop-down.expanded {
    transform: rotate(0);
}
.icon-arrow-drop-down > * {
    fill: var(--gv-body-color);
}

.common-log-view-mode .ant-segmented-item-icon svg {
    width: 16px;
    height: 16px;
}

.common-search-input {
    width: 100%;
}
.common-search-input .ant-input-suffix > svg {
    width: 14px;
    height: 14px;
}
.common-search-input .ant-input-suffix > svg > path {
    fill: #808080;
}

.target-dropdown-pos {
    position: fixed;
    z-index: -1;
}