@import url('./GV-Cloud.css');

body {
    --ascloud-system-color: #88a1be;
    --ascloud-system-font-color: #fff;
    --ascloud-second-bg: #E2E6ED;
    --ascloud-text-disabled-color: rgba(0, 0, 0, 0.4);
    --ascloud-component-bg: #fff;
    --asCloud-border-color: rgba(0,0,0,.125);

    --ascloud-lockdown-color: #ee4f4a;
    --ascloud-lockdown-text: #fff;
    --ascloud-lockdown-hover-bg: #ffffff99;
    --ascloud-lockdown-active-button-bg: #FFD9D8;
    
    --ascloud-form-label-color: #4c78ac;
    --ascloud-form-switch-bg: #00c700;

    --ascloud-card-border-radius: 6px;
    --ascloud-card-header-bg: #E2E6ED;

    --ascloud-icon-btn: #a1a3a8;
    --ascloud-icon-btn-disabled: #a1a3a8;

    --ascloud-box-shadow: 0px 5px 10px rgb(0 0 0 / 15%);

    --ascloud-star-color: #FF8C28;
}
body.dark {
    --ascloud-system-color: #404247;
    --ascloud-system-font-color: #d9d9d9;
    --ascloud-second-bg: #232323;
    --ascloud-text-disabled-color: #d9d9d966;
    --ascloud-component-bg: #232323;
    --asCloud-border-color: #404247;

    --ascloud-form-label-color: #d9d9d9;

    --ascloud-card-header-bg: #313336;

    --ascloud-icon-btn: #a1a3a8;
    --ascloud-icon-btn-disabled: #4c4d4f;

    --ascloud-star-color: #FFCF23;
}

.App {
    text-align: center;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
.text-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

input:-webkit-autofill {
    caret-color: var(--gv-input-color);
    -webkit-background-clip: text;
    -webkit-box-shadow: 0 0 0 30px var(--gv-input-bg) inset !important;
    -webkit-text-fill-color: var(--gv-input-color) !important;
    transition: background-color 5000s ease-in-out 0s;
}
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--gv-input-hover-bg) inset !important;
}
:focus-visible {
    outline: none;
}
a {
    text-decoration: none;
}

/* OverWrite bootstrap */
.h2, h2 {
    font-weight: bold !important;
}
h1, h2, h3, h4, h5, h6 {
    color: var(--gv-body-color);
}

.btn {
    --bs-btn-font-size: var(--gv-body-font-size);
    --bs-btn-focus-box-shadow: none;
    border-radius: var(--gv-btn-border-radius);
}
.btn-primary {
    --bs-btn-color: var(--gv-btn-primary-color);
    --bs-btn-bg: var(--gv-btn-primary-bg);
    --bs-btn-border-color: var(--gv-btn-primary-bg);
    --bs-btn-hover-color: var(--gv-btn-primary-color);
    --bs-btn-hover-bg: var(--gv-btn-primary-hover-bg);
    --bs-btn-hover-border-color: var(--gv-btn-primary-hover-bg);
    --bs-btn-active-color: var(--gv-btn-primary-color);
    --bs-btn-active-bg: var(--gv-btn-primary-active-bg);
    --bs-btn-active-border-color: var(--gv-btn-primary-active-bg);
    --bs-btn-disabled-color: var(--gv-btn-primary-disabled-color);
    --bs-btn-disabled-bg: var(--gv-btn-primary-disabled-bg);
    --bs-btn-disabled-border-color: var(--gv-btn-primary-disabled-bg);
}
.btn-secondary {
    --bs-btn-color: var(--gv-btn-secondary-color);
    --bs-btn-bg: var(--gv-btn-secondary-bg);
    --bs-btn-border-color: var(--gv-btn-secondary-border-color);
    --bs-btn-hover-color: var(--gv-btn-secondary-hover-color);
    --bs-btn-hover-bg: var(--gv-btn-secondary-hover-bg);
    --bs-btn-hover-border-color: var(--gv-btn-secondary-border-color);
    --bs-btn-active-color: var(--gv-btn-secondary-hover-color);
    --bs-btn-active-bg: var(--gv-btn-secondary-active-bg);
    --bs-btn-active-border-color: var(--gv-btn-secondary-border-color);
    --bs-btn-disabled-color: var(--gv-btn-secondary-disabled-color);
    --bs-btn-disabled-bg: var(--gv-btn-secondary-disabled-bg);
    --bs-btn-disabled-border-color: var(--gv-btn-secondary-disabled-border-color);
}
.btn-danger {
    --bs-btn-color: var(--gv-btn-danger-color);
    --bs-btn-bg: var(--gv-btn-danger-bg);
    --bs-btn-border-color: var(--gv-btn-danger-bg);
    --bs-btn-hover-color: var(--gv-btn-danger-color);
    --bs-btn-hover-bg: var(--gv-btn-danger-hover-bg);
    --bs-btn-hover-border-color: var(--gv-btn-danger-hover-bg);
    --bs-btn-active-color:var(--gv-btn-danger-color);
    --bs-btn-active-bg: var(--gv-btn-danger-active-bg);
    --bs-btn-active-border-color: var(--gv-btn-danger-active-bg);
    --bs-btn-disabled-color: var(--gv-btn-danger-disabled-color);
    --bs-btn-disabled-bg: var(--gv-btn-danger-disabled-bg);
    --bs-btn-disabled-border-color: var(--gv-btn-danger-disabled-bg);
}

.btn-primary.action, .btn-secondary.action, .btn-danger.action {
    padding: 4px 30px;
    white-space: nowrap;
}

.dark .btn-close {
    filter: invert(1) grayscale(100%) brightness(200%);
}
/* .modal-content {
    border-radius: 1rem;
} */

.list-group-item {
    color: var(--gv-list-item-color);
    background-color: var(--gv-list-item-bg);
    border-color: var(--gv-list-item-border-color);
    transition: background-color .3s;
}
.list-group-item:hover {
    background-color: var(--gv-list-item-hover-bg);
    border-color: var(--gv-list-item-hover-bg);
}
.list-group-item.active {
    background-color: var(--gv-list-item-selected-bg);
    border-color: var(--gv-list-item-selected-bg);
    color: var(--gv-list-item-selected-color);
}
.list-group-item.disabled, .list-group-item:disabled {
    color: var(--gv-list-item-disabled-color);
}

.invalid-feedback {
    text-align: left;
}
.col-form-label {
    text-align: right;
}

.ant-picker-ok>.ant-btn-primary {
    border-radius: var(--gv-btn-border-radius);
}

.card {
    --bs-card-border-radius: var(--ascloud-card-border-radius);
    --bs-card-inner-border-radius: calc(var(--ascloud-card-border-radius) - 1px);

    background-color: var(--ascloud-component-bg);
    border-color: var(--asCloud-border-color);
}
.card-header {
    font-size: 16px;
    color: var(--gv-body-color);
    background-color: var(--ascloud-card-header-bg);
    border-bottom: none;
}
.card-body {
    color: var(--gv-body-color);
}
.accordion-button:focus {
    box-shadow: 0 0 0 0.1rem rgb(13 110 253 / 25%);
}
.accordion-item {
    background-color: var(--ascloud-component-bg);
}
.accordion-button::after {
    width: 16px;
    height: 16px;
    background-size: 16px;
}
.accordion-button::after, .accordion-button:not(.collapsed)::after {
    background-image: url(images/accordion_arrowdown.svg);
}


.badge.rounded-pill {
    font-size: 14px;
    background-color: #E2E6ED !important;
    color: #383838;
    border-radius: 6px !important;
}
.dark .badge.rounded-pill {
    background-color: var(--gv-input-bg) !important;
    color: var(--gv-body-color);
}

.dark .table {
    color: var(--gv-body-color);
    border-color: var(--asCloud-border-color);
    --bs-table-hover-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: var(--ascloud-system-font-color);
}

/* Floating Label*/
/* .form-floating {
    margin-top: 0.6rem;
}
.form-floating>.form-control, .form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown) {
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    height: initial;
}
.form-floating>label {
    padding: 0.375rem 0.75rem;
    width: initial;
    height: initial;
    color: #999;
    border: none;
}
.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label {
    transform: scale(.85) translateY(-1rem) translateX(0.15rem);
    opacity: 1;
    padding: 0 0.2rem;
    background: #fff;
    margin-left: 0.7rem;
    margin-top: 0.25rem;
}
.form-floating>.form-control:not(:placeholder-shown)~label {
    background: linear-gradient(0deg, var(--gv-input-bg) 50%, var(--ascloud-component-bg) 50%);
}
.form-floating>.form-control:focus~label, .form-floating>.form-control:focus:not(:placeholder-shown)~label, .form-floating>.form-control:hover:not(:placeholder-shown)~label {
    background: linear-gradient(0deg, var(--gv-input-hover-bg) 50%, var(--ascloud-component-bg) 50%);
}
.form-floating>.form-control:not(:placeholder-shown):disabled ~ label {
    background: linear-gradient(0deg, #e9ecef 50%, #fff 50%);
}
.dark .form-floating>.form-control:not(:placeholder-shown):disabled ~ label {
    background: linear-gradient(0deg, var(--gv-input-disabled-bg) 50%, var(--ascloud-component-bg) 50%);
} */

.popover {
    position: absolute;
}
.popover-body {
    color: var(--gv-dropdown-menu-color);
}
.popover .popover-arrow {
    position: relative;
}
.bs-popover-bottom>.popover-arrow::before {
    border-bottom-color: var(--gv-dropdown-menu-border-color);
}
.bs-popover-top>.popover-arrow::before {
    border-top-color: var(--gv-dropdown-menu-border-color);
}
.bs-popover-bottom>.popover-arrow::after {
    border-bottom-color: var(--gv-dropdown-menu-bg);
}
.bs-popover-top>.popover-arrow::after {
    border-top-color: var(--gv-dropdown-menu-bg);
}

.tooltip, .tooltip .tooltip-arrow {
    position: absolute;
}

.nav {
    --bs-nav-link-color: var(--gv-tab-color);
    --bs-nav-link-hover-color: var(--gv-tab-hover-color);
}
.nav .nav-link.active {
    color: var(--gv-tab-selected-color);
}

/* End of OverWrite bootstrap */


/* OverWrite Ant Design */
@import url(./antDesign.css);

.as-ant-select {
    text-align: left;
}
.as-ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: var(--gv-input-border-radius);
    border-color: var(--gv-input-border-color);
}
.as-ant-select.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    background-color: var(--gv-input-hover-bg);
    border-color: var(--gv-input-hover-border-color);
}
.dark .as-ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--gv-input-bg);
    color: var(--gv-body-color);
}
.ant-select-multiple.as-ant-select.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-single.as-ant-select.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector  {
    background-color: var(--gv-input-disabled-bg);
    color: var(--gv-input-disabled-color);
}


.as-ant-select.ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item {
    background: var(--gv-btn-primary-bg);
    color: var(--gv-btn-primary-color);
    border-radius: var(--gv-input-border-radius);
    border-color: transparent;
}
.as-ant-select.ant-select-disabled .ant-select-selection-overflow .ant-select-selection-item {
    color: var(--gv-btn-primary-disabled-color);
    background: var(--gv-btn-primary-disabled-bg);
}


.dark .as-ant-select .ant-select-clear {
    color: var(--gv-body-color);
    background-color: var(--gv-input-bg);
}
.dark .as-ant-select .ant-select-arrow {
    color: var(--gv-body-color);
}
.as-ant-select.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
    background-color: var(--gv-input-hover-bg);
    border-color: var(--gv-input-hover-border-color);
}
.as-ant-select.ant-select-allow-clear .ant-select-selector {
	padding-right: 40px;
}
.as-ant-select.ant-select-allow-clear .ant-select-clear {
    right: 26px;
}
.ant-select .ant-select-selection-placeholder {
    color: var(--gv-input-placeholder-color);
}
.as-ant-select.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    background-color: var(--gv-input-hover-bg);
}

.dark .ant-select-dropdown, .dark .ant-select-tree {
    background-color: var(--ascloud-component-bg);
    color: var(--ascloud-system-font-color);
}
.ant-select-dropdown {
    border-radius: var(--gv-input-border-radius);
    border: 1px solid var(--asCloud-border-color);
}

.ant-select-dropdown .ant-select-item {
    text-align: left;
    color: var(--gv-list-item-color);
    background-color: var(--gv-list-item-bg);
}
.ant-select-dropdown .ant-select-item.ant-select-item-option-active {
    background-color: var(--gv-list-item-hover-bg);
}
.ant-select-dropdown .ant-select-item.ant-select-item-option-selected {
    color: var(--gv-list-item-selected-color);
    background-color: var(--gv-list-item-selected-bg);
}


.dark .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: #badeff;
}
.dark .ant-select-item.ant-select-item-option-disabled  {
    color: #666;
}

.as-ant-select.ant-select-single .ant-select-arrow .anticon-down, 
.as-ant-select.ant-select-multiple .ant-select-arrow .anticon-down {
    position: relative;
}
.as-ant-select.ant-select-single .ant-select-arrow .anticon-down > svg,
.as-ant-select.ant-select-multiple .ant-select-arrow .anticon-down > svg {
    width: 0;
}
.as-ant-select.ant-select-single:not(.ant-select-disabled) .ant-select-arrow .anticon-down::after,
.as-ant-select.ant-select-multiple:not(.ant-select-disabled) .ant-select-arrow .anticon-down::after {
    content: '';
    background: url(images/BsChevronDown.svg) no-repeat;
    width: 12px;
    height: 12px;
    position: absolute;
    right: 0;
}
.dark .as-ant-select.ant-select-single:not(.ant-select-disabled) .ant-select-arrow .anticon-down::after,
.dark .as-ant-select.ant-select-multiple:not(.ant-select-disabled) .ant-select-arrow .anticon-down::after {
    background: url(images/accordion_arrowdown.svg) no-repeat;
    background-size: 12px 12px;
    top: 1px;
}
.ant-select .ant-select-arrow .anticon-down::after {
    transition: all 0.3s;
}
.ant-select.ant-select-open .ant-select-arrow .anticon-down::after {
    transform: rotate(-180deg);
}

.ant-select-multiple .ant-select-selection-item-remove .anticon-close::after {
    content: '';
    background: url(images/delete_red.svg);
    background-size: contain;
    display: inline-block;
    width: 14px;
    height: 14px;
}
.ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item-remove svg,
.ant-select-multiple.ant-select-sm .ant-select-selection-overflow .ant-select-selection-item-remove svg {
    display: none;
}
/* .anticon.anticon-close::after {
    content: '';
    background: url(images/delete_red.svg);
    background-size: contain;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-top: -2px;
}
.ant-modal .anticon.anticon-close::after {
    display: none;
}
.ant-select-multiple .ant-select-selection-item-remove svg {
    display: none !important;
}
.as-ant-select .ant-select-clear svg {
    margin-top: -0.3em;
} */

.as-ant-dropdown .ant-select-tree-node-content-wrapper {
    overflow-x: hidden;
}

.dark .ant-empty {
    color: var(--gv-body-color);
}

.ant-tree {
    color: var(--gv-body-color);
    background-color: var(--ascloud-component-bg);
}
.ant-tree {
    color: var(--gv-body-color);
    background-color: var(--ascloud-component-bg);
}
.ant-tree .ant-tree-treenode:not(.ant-tree-treenode-disabled) .ant-tree-node-content-wrapper:hover {
    color: var(--gv-list-item-color);
    background-color: var(--gv-list-item-hover-bg);
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    color: var(--gv-list-item-selected-color);
    background-color: var(--gv-list-item-selected-bg);
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
    color: var(--gv-list-item-disabled-color);
}

.as-select-title {
    display: flex;
    align-items: center;
    grid-gap: 0.5em;
}
.as-select-title > svg {
    flex: 0 0 auto;
    width: 18px;
    height: 18px;
}
.as-select-title.region > svg path {
    fill: var(--ascloud-system-color);
}
.dark .as-select-title.region > svg path {
    fill: var(--gv-body-color);
}
.ant-select-tree-treenode-disabled .as-select-title.region > svg path {
    fill: var(--gv-btn-primary-disabled-bg);
}
.as-ant-select .as-select-title > svg path {
    fill: var(--ascloud-system-font-color);
}

.ant-select.ant-select-multiple .as-select-title.gate svg,
.ant-select.ant-select-multiple .as-select-title.device svg,
.ant-select.ant-select-multiple .as-select-title.region svg {
    width: 16px;
    height: 16px;
}


.dark .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
    background-color: #555;
    border-color: #5f5f5f !important;
}

.as-ant-dropdown {
    z-index: 1070;
}


.ant-dropdown-menu {
    padding: 4px;
}
.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
    white-space: nowrap;
}

.ant-dropdown-menu-item-divider, .ant-dropdown-menu-submenu-title-divider {
    background-color: var(--gv-dropdown-menu-item-separator-color);
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled, .ant-dropdown-menu-item.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-disabled, .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-disabled:hover,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-disabled, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-disabled, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-disabled:hover {
    color: var(--gv-dropdown-menu-item-disabled-color);
    background-color: var(--gv-dropdown-menu-bg);
}

.ant-checkbox-inner, .ant-tree-checkbox-inner, .ant-select-tree-checkbox-inner {
    background-color: var(--gv-checkbox-bg);
    border-color: var(--gv-checkbox-border);
    border-radius: var(--gv-checkbox-border-radius);
}
.ant-checkbox-input:focus+.ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner,
.ant-tree-checkbox-input:focus+.ant-tree-checkbox-inner, .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner, .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner {
    border-color: var(--gv-checkbox-hover-border);
}
.ant-checkbox-checked .ant-checkbox-inner,
.ant-tree-checkbox-checked .ant-tree-checkbox-inner,
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    background-color: var(--gv-checkbox-checked-bg);
    border-color: var(--gv-checkbox-checked-border);
}
.ant-checkbox-checked:hover .ant-checkbox-inner,
.ant-tree-checkbox-checked:hover .ant-tree-checkbox-inner,
.ant-select-tree-checkbox-checked:not(.ant-select-tree-checkbox-disabled):hover .ant-select-tree-checkbox-inner {
    background-color: var(--gv-checkbox-checked-hover-bg);
    border-color: var(--gv-checkbox-checked-hover-border);
}
.ant-checkbox-checked:after, .ant-tree-checkbox-checked:after,
.ant-select-tree-checkbox-checked:after {
    border-color: var(--gv-checkbox-checked-border);
    border-radius: var(--gv-checkbox-border-radius);
}
.ant-checkbox-indeterminate .ant-checkbox-inner:after,
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner:after {
    background-color: var(--gv-checkbox-checked-bg);
}
.ant-checkbox-wrapper:hover .ant-checkbox-indeterminate .ant-checkbox-inner:after,
.ant-select-tree-checkbox-indeterminate:hover .ant-select-tree-checkbox-inner:after {
    background-color: var(--gv-checkbox-checked-hover-bg);
}
.ant-checkbox-disabled .ant-checkbox-inner,
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
    background-color: var(--gv-input-disabled-bg);
    border-color: var(--gv-list-item-border-color) !important;
}
.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner:after {
    border-color: var(--gv-input-disabled-color);
}
.ant-checkbox-disabled + span {
    color: var(--gv-dropdown-menu-item-disabled-color);
}

.ant-form-item .ant-form-item-label >label {
    color: var(--ascloud-form-label-color);
    font-weight: 700;
}
.ant-form-item {
    color: var(--gv-body-color);
    margin-bottom: 20px;
}
.ant-form-item-explain-error {
    font-size: 12px;
    text-align: left;
}

.ant-slider .ant-slider-rail {
    background-color: var(--gv-slider-rail-bg);
}
.ant-slider:hover .ant-slider-rail {
    background-color: var(--gv-slider-rail-hover-bg);
}
.ant-slider.ant-slider-disabled .ant-slider-rail {
    background-color: var(--gv-slider-rail-disabled-bg) !important;
}
.ant-slider .ant-slider-track {
    background-color: var(--gv-slider-track-bg);
}
.ant-slider:hover .ant-slider-track {
    background-color: var(--gv-slider-track-hover-bg);
}
.ant-slider.ant-slider-disabled .ant-slider-track {
    background-color: var(--gv-slider-track-disabled-bg) !important;
}
.ant-slider .ant-slider-handle {
    background-color: var(--gv-slider-track-bg);
    border-color: var(--gv-slider-track-bg);
}



.ant-input:hover:not(.ant-input-disabled), .ant-input-password:not(.ant-input-affix-wrapper-disabled):hover, 
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover, .ant-input-password:hover .ant-input:not(:disabled),
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover .ant-input:not(:disabled),
.ant-input-number:not(.ant-input-number-disabled):hover {
    background-color: var(--gv-input-hover-bg);
    border-color: var(--gv-input-hover-border-color);
}
.ant-input-focused, .ant-input:focus, .ant-input-password:focus, .ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus, .ant-input-number-focused {
    background-color: var(--gv-input-hover-bg);
    border-color: var(--gv-input-hover-border-color);
    box-shadow: none;
}
.ant-input[disabled], .ant-input-password[disabled], .ant-input-affix-wrapper-disabled, .ant-input-number-disabled {
    color: var(--gv-input-disabled-color);
    background-color: var(--gv-input-disabled-bg);
    border-color: var(--gv-input-border-color);
}
.ant-input[disabled]:hover, .ant-input-password[disabled]:hover, .ant-input-affix-wrapper-disabled:hover, .ant-input-number-disabled:hover {
    border-color: var(--gv-input-border-color); 
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
    background-color: var(--gv-input-hover-bg);
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    background-color: var(--gv-input-bg);
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background-color: var(--gv-input-hover-bg);
}
.ant-input-number {
    width: 100%;
}
.ant-input-number .ant-input-number-handler-wrap {
    border-radius: 0 var(--gv-input-border-radius) var(--gv-input-border-radius) 0;
    background: var(--gv-input-hover-bg);
}


.ant-input-password > .ant-input {
    border-radius: 0;
}
.ant-input-password .ant-input-suffix > svg {
    width: 16px;
    height: 16px;
}
.ant-input-password:not(.ant-input-affix-wrapper-disabled) .ant-input-suffix > svg {
    cursor: pointer;
}

.ant-input-group {
    color: var(--gv-input-color);
}
.ant-input-group-addon {
    color: var(--gv-input-color);
    background-color: var(--gv-input-bg);
    border-color: var(--gv-input-border-color);
    border-radius: var(--gv-input-border-radius);
}
.ant-input-group-addon .ant-select-focused .ant-select-selector, .ant-input-group-addon .ant-select-open .ant-select-selector {
    color: var(--gv-input-color);
}

.ant-input-group.ant-input-group-compact>.ant-cascader-picker:first-child 
.ant-input, .ant-input-group.ant-input-group-compact>.ant-select-auto-complete:first-child .ant-input, 
.ant-input-group.ant-input-group-compact>.ant-select:first-child>.ant-select-selector, 
.ant-input-group.ant-input-group-compact>:first-child {
    border-top-left-radius: var(--gv-input-border-radius);
    border-bottom-left-radius: var(--gv-input-border-radius);
}
.ant-input-group.ant-input-group-compact>.ant-cascader-picker-focused:last-child .ant-input, 
.ant-input-group.ant-input-group-compact>.ant-cascader-picker:last-child .ant-input, 
.ant-input-group.ant-input-group-compact>.ant-select:last-child>.ant-select-selector, 
.ant-input-group.ant-input-group-compact>:last-child {
    border-top-right-radius: var(--gv-input-border-radius);
    border-bottom-right-radius: var(--gv-input-border-radius);
}

.ant-input-affix-wrapper .ant-input-clear-icon svg {
    color: rgba(0, 0, 0, .25);
    opacity: 0;
    transition: opacity .3s;
}
.dark .ant-input-affix-wrapper .ant-input-clear-icon svg {
    color: var(--gv-body-color);
}
.ant-input-affix-wrapper:hover .ant-input-clear-icon svg {
    opacity: 1;
}

.ant-spin {
    color: var(--gv-body-color);
}
.ant-spin .ant-spin-dot-item {
    background-color: var(--gv-body-color);
}

.ant-notification {
    color: var(--gv-body-color);
    max-height: calc(100vh * 0.5);
    overflow-x: hidden;
    overflow-y: auto;
}
.ant-notification::-webkit-scrollbar-thumb  {
    background-color: transparent;
}
.ant-notification:hover::-webkit-scrollbar-thumb  {
    background-color: var(--gv-scrollbar-bg);
}
.ant-notification > div {
    display: flex;
    flex-direction: column-reverse;
    padding: 1em;
}
.ant-notification .ant-notification-notice {
    background-color: var(--ascloud-component-bg);
    border-radius: var(--gv-btn-border-radius);
    box-shadow: none;
    margin-bottom: 8px;
}
.ant-notification .ant-notification-notice-message {
    color: var(--gv-body-color);
}
.ant-notification .ant-notification-notice-close {
    color: var(--gv-body-color);
}
.ant-notification .anticon.anticon-close::after {
    display: none;
}



.ant-modal .ant-modal-body {
    padding: 1rem;
}
.ant-modal .ant-modal-content {
    padding: 12px;
}
.ant-modal .ant-modal-title {
    font-weight: 700;
    font-size: 24px;
}
.ant-modal-header {
    border-bottom: none;
}
.ant-modal-footer {
    border-top: none;
    border-radius: 0 0 var(--gv-modal-border-radius) var(--gv-modal-border-radius);
    display: flex;
    justify-content: flex-end;
    grid-gap: 0.5em;
    padding-bottom: 16px;
}
.ant-modal-footer .ant-btn {
    min-width: 75px;
    padding: 4px 20px;
}
.ant-modal-footer > .space {
    flex: 1 1 auto;
}

/* .ant-modal .ant-form-item .ant-form-item-label > label, */
.ant-modal-confirm .ant-modal-confirm-body .ant-modal-confirm-title,
.ant-modal-confirm .ant-modal-confirm-body .ant-modal-confirm-content {
    color: var(--gv-body-color);
}

.ant-empty-image {
    height: 80px;
}
/* End of OverWrite Ant Design */

.app-main {
    display: flex;
    width: 100%;
    height: 100vh;

    --app-main-siderbar-logo-padding: 10px;
}

/* start sidebar */
.app-sidebar {
    display: flex;
    flex-direction: column;
    width: var(--gv-sidebar-collapse-width);
    height: 100%;
    text-align: left;
    box-sizing: border-box;
    flex-shrink: 0;
    background-color: var(--gv-sidebar-bg);
    transition: width .4s;
    padding: 10px 0;
    position: fixed;
    z-index: 100;
}
.app-sidebar.lockdown {
    background-color: var(--ascloud-lockdown-color);
}

.app-sidebar > ul {
    flex: 1 1 auto;
    width: 100%;
    list-style: none;
    position: relative;
    margin: 0;
    padding-left: 0;
    display: inline-flex;
    flex-direction: column;
    overflow: hidden auto;
}

.app-sidebar > ul > li {
    position: relative;
}

.app-sidebar > ul a {
    display: block;
    height: var(--gv-sidebar-item-height);
    line-height: var(--gv-sidebar-item-height);
    font-size: 14px;
    /* font-weight: bold; */
    color: var(--gv-sidebar-color);
    padding: 0 20px;
    list-style: none;
    cursor: pointer;
    position: relative;
    transition: border-color .3s, background-color .3s, color .3s;
    box-sizing: border-box;
    white-space: nowrap;
}
.app-sidebar > ul a.active {
    color: var(--gv-sidebar-item-selected-color);
    background: var(--gv-sidebar-item-selected-bg);
}

.app-sidebar > ul a svg {
    width: var(--gv-sidebar-icon-size);
    height: var(--gv-sidebar-icon-size);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc((var(--gv-sidebar-collapse-width) - var(--gv-sidebar-icon-size)) / 2);
}
.app-sidebar > ul a svg path {
    fill: currentColor;
}

.app-sidebar > ul a span {
    position: absolute;
    left: calc(var(--gv-sidebar-collapse-width) - 8px);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s ease-in-out;

    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(var(--gv-sidebar-expand-width) - 52px - 24px);
}

.open .app-sidebar {
    width: var(--gv-sidebar-expand-width);
}

.open .app-sidebar>ul a span {
    visibility: visible;
    opacity: 1;
}

.app-sidebar>ul>li:active a:not(.active) {
    color: var(--gv-sidebar-item-active-color);
    background-color: var(--gv-sidebar-item-active-bg);
}
.app-sidebar>ul>li:hover a {
    color: var(--gv-sidebar-item-hover-color);
    outline: none;
    background-color: var(--gv-sidebar-item-hover-bg);
}

.app-sidebar.lockdown > ul a {
    color: var(--ascloud-lockdown-text);
}
.app-sidebar.lockdown > ul a.active {
    color: var(--ascloud-lockdown-color);
    background-color: var(--ascloud-lockdown-text);
}
.app-sidebar.lockdown > ul > li:hover a {
    color: var(--ascloud-lockdown-color);
    background-color: var(--ascloud-lockdown-hover-bg);
}


.siderbar-dock-button {
    width: 14px;
    height: 48px;
    position: absolute;
    z-index: 101;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 0.3s;
    opacity: 0;
}
.app-sidebar:hover .siderbar-dock-button {
    opacity: 1;
}
/* .siderbar-dock-button::before {
    content: '';
    position: absolute;
    left: 0;
    top: 25%;
    width: 100%;
    height: 50%;
    background-color: var(--gv-body-color);
} */
.siderbar-dock-button > svg {
    position: absolute;
}
.siderbar-dock-button > svg path:nth-child(1) {
    fill: var(--gv-sidebar-btn-bg);
    fill-opacity: .8;
}
.siderbar-dock-button:hover > svg path:nth-child(1) {
    fill-opacity: .9;
}
.siderbar-dock-button:active > svg path:nth-child(1) {
    fill-opacity: 1;
}
.siderbar-dock-button > svg path:nth-child(2) {
    stroke: var(--gv-sidebar-btn-arrow-color);
}

.app-sidebar-brand {
    height: var(--gv-sidebar-collapse-width);
    padding: var(--app-main-siderbar-logo-padding);
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
}
.app-sidebar-brand > div {
    width: calc(var(--gv-sidebar-collapse-width) - var(--app-main-siderbar-logo-padding) * 2);
    height: 100%;
    min-height: calc(var(--gv-sidebar-collapse-width) - var(--app-main-siderbar-logo-padding) * 2);
    flex: 0 0 auto;
    background-color: var(--gv-sidebar-logo-bg);
    border-radius: 50%;
    padding: 0 18px;
    transition: all 0.4s;
    display: flex;
    align-items: center;
}
.open .app-sidebar-brand > div {
    background-color: transparent;
    width: 100%;
    justify-content: start;
}
.app-sidebar-brand > span {
    flex: 1 1 auto;
    line-height: calc(var(--gv-sidebar-collapse-width) - var(--app-main-siderbar-logo-padding) * 2);
    color: #fff;
    font-size: 1.7em;
    overflow: hidden;
}
.open .app-sidebar-brand > div img {
    transform: translateY(-4px);
}

.app-sidebar-photo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--app-main-siderbar-logo-padding);
    cursor: pointer;
    position: relative;
}
.app-sidebar-photo > .gv-user-avatar {
    width: var(--gv-sidebar-avatar-size);
    height: var(--gv-sidebar-avatar-size);
}

.app-sidebar-photo > span {
    visibility: hidden;
    transition: all .3s;
    color: var(--gv-sidebar-color);
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    margin-top: 8px;
}
.open .app-sidebar-photo > span {
    visibility: visible;
}
.app-sidebar-photo > .suspend {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 36px;
    left: calc(50% + 4px);
}



.app-sidebar-lockdown {
    border: none;
    display: flex;
    align-items: center;
    align-self: center;
    margin: 1em 0;
    color: var(--ascloud-lockdown-color);
    padding: 0 calc((var(--gv-sidebar-collapse-width) - 48px)/2);
    background-color: transparent;
    transition: all .3s;
}
.app-sidebar-lockdown > div {
    flex: 0 0 auto;
    width: 48px;
    height: 48px;
    min-height: 48px;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--gv-user-avatar-bg);
    transition: all .3s;
}

.app-sidebar-lockdown > svg {
    flex: 0 0 auto;
    transition: all .3s;
}
.app-sidebar-lockdown > span {
    color: var(--ascloud-system-font-color);
    width: 0;
    overflow: hidden;
    white-space: nowrap;
    font-weight: bold;
}

.open .app-sidebar-lockdown {
    border-radius: 8px;
    grid-gap: 0.5em;
    margin: 1em auto;
}
.open .app-sidebar-lockdown > span {
    width: 100%;
}
.app-sidebar-lockdown:hover > div {
    background-color: var(--gv-sidebar-item-hover-bg);
}


.lockdown .app-sidebar-lockdown > div {
    background-color: var(--ascloud-lockdown-active-button-bg);
}
.lockdown .app-sidebar-lockdown:hover > div {
    background-color: var(--ascloud-lockdown-hover-bg);
}

.open .lockdown .app-sidebar-lockdown {
    width: calc(100% - 24px);
    background-color: var(--ascloud-lockdown-active-button-bg);
    padding: 0 4px;
}
.open .lockdown .app-sidebar-lockdown:hover {
    background-color: var(--ascloud-lockdown-hover-bg);
}
.open .lockdown .app-sidebar-lockdown > div {
    background-color: transparent;
}
.open .lockdown .app-sidebar-lockdown > span {
    color: currentColor;
}
/* end sidebar */


.app-content {
    margin-left: var(--gv-sidebar-collapse-width);
    width: calc(100% - var(--gv-sidebar-collapse-width));
    max-width: 100%;
    overflow: hidden;
    padding: 10px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    transition: 0.4s all;
}
.open .app-content {
    margin-left: var(--gv-sidebar-expand-width);
    width: calc(100% - var(--gv-sidebar-expand-width));
}
.app-content-header {
    padding: 10px;
}
.app-content-header > h4 {
    font-weight: bold;
}
.app-content-main {
    flex-grow: 1;
}
.app-content-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 0.25rem;
}
.ant-modal .app-content-footer {
    padding-bottom: 0;
}
.app-content-footer>* {
    margin: 0.25rem;
}

.app-setup {
    background-color: var(--ascloud-component-bg);
    /* border: 1px solid var(--asCloud-border-color); */
    border-radius: 6px;
}

.scroll-box {
    overflow: auto;
}
.scroll-box::-webkit-scrollbar-thumb  {
    background-color: transparent;
}
.scroll-box:hover::-webkit-scrollbar-thumb  {
    background-color: var(--gv-scrollbar-bg);
}

.geo64-font {
    font-family: monospace, 'Courier New';
}

.circle-btn {
    border-radius: 50%;
}

/* Icon Button */
.icon-btn, .icon-check-btn {
    color: var(--gv-btn-primary-bg);
    cursor: pointer;
    width: 24px;
    height: 24px;
    opacity: 1;
}
.icon-btn > path, .icon-check-btn > path {
    fill: currentColor;
}
.icon-btn:hover, .icon-check-btn:hover {
    opacity: 0.7;
}
.icon-btn:active, .icon-check-btn:active {
    opacity: 0.9;
}

.icon-check-btn {
    opacity: 0.6;
}
.icon-check-btn.checked {
    border-radius: 3px;
    opacity: 1;
}
.icon-btn.disabled, .icon-btn:disabled, .icon-check-btn.disabled, .icon-check-btn:disabled {
    pointer-events: none;
    opacity: 0.2;
}
/* End of Icon Button */

/* Image Button */
.image-btn {
    padding: 0;
    background: transparent;
    border: none;
    margin: 0.2em;
    cursor: pointer;
}
.image-btn > svg {
    color: var(--ascloud-icon-btn);
    width: 20px;
    height: 20px;
}
.image-btn > svg > path {
    fill: currentColor;
}
.image-btn:hover > svg {
    color: var(--gv-btn-primary-bg);
}

.image-btn.checked {
    cursor: initial;
}
.image-btn.checked > svg {
    color: var(--gv-btn-primary-bg);
}
.image-btn.disabled, .image-btn:disabled {
    pointer-events: none;
}
.image-btn.disabled > svg, .image-btn:disabled > svg {
    color: var(--ascloud-icon-btn-disabled);
}
/* End of Image Button */


.region-icon path {
    fill: var(--ascloud-system-color);
}
.dark .region-icon path {
    fill: var(--gv-body-color);
}

.dark .device-icon path {
    fill: #a1a3a8;
}

.delete-circle {
    color: #EF3C3C;
}
.delete-circle:hover {
    color: #FF3D3D;
}
.delete-circle path {
    fill: currentColor;
}

.edit-icon {
    color: #85A0D0;
}
.edit-icon:hover {
    color: #4583B9;
}
.edit-icon path {
    fill: currentColor;
}

.app-debug {
    position: absolute;
    top: 0;
    color: #fff;
    font-weight: bold;
    text-shadow: 1px 1px black, -1px -1px black, 1px -1px black, -1px 1px black;
    z-index: 9999;
}

.icon-no-data > * {
    fill: #D0D1D4;
}
.dark .icon-no-data > * {
    fill: #707070;
}

.icon-star path {
    fill: var(--ascloud-star-color);
}
.icon-star-outline path {
    stroke: var(--ascloud-star-color);
}

.icon-mobile > * {
    fill: #5772A5;
}
.dark .icon-mobile > * {
    fill: #5490E9;
}