.log-view-main {
    width: 100%;
    height: 100%;
    position: relative;
}
.log-view-main.photo-view {
    padding: 0.5em;
}

@keyframes new-log-animation {
    from { box-shadow: var(--new-log-box-shadow); }
    to { box-shadow: var(--log-item-box-shadow); }
}
.log-item-new {
    --new-log-shadow-color-rgb: 0 128 0;
    --new-log-box-shadow: 0 10px 20px rgb(var(--new-log-shadow-color-rgb) / 20%), inset 0 5px 10px rgb(var(--new-log-shadow-color-rgb) / 50%), 
                            10px 0px 20px rgb(var(--new-log-shadow-color-rgb) / 20%), inset 5px 0px 10px rgb(var(--new-log-shadow-color-rgb) / 50%), 
                            -10px 0px 20px rgb(var(--new-log-shadow-color-rgb) / 20%), inset -5px 0px 10px rgb(var(--new-log-shadow-color-rgb) / 50%), 
                            0 -10px 20px rgb(var(--new-log-shadow-color-rgb) / 20%), inset 0 -5px 10px rgb(var(--new-log-shadow-color-rgb) / 50%);

    animation: new-log-animation 5s ease-out;
}
.log-item-new.warning {
    --new-log-shadow-color-rgb: 128 0 0;
}
.dark .log-item-new {
    --new-log-shadow-color-rgb: 0 192 0;
}
.dark .log-item-new.warning {
    --new-log-shadow-color-rgb: 192 0 0;
}

/* Photo view */
.log-photo-view {
    --photo-view-item-padding: 20px;
    --photo-view-image-width: 320px;
    --photo-view-image-height: 180px;
    --photo-view-photo-size: 60px;
    --photo-view-font-size: 14px;
    --log-item-box-shadow: 0px 4px 8px rgb(0 0 0 / 20%);

    display: flex;
    flex-wrap: wrap;
    grid-gap: var(--photo-view-item-padding);
}
.dark .log-photo-view {
    --log-item-box-shadow: 0px 4px 8px rgb(0 0 0 / 80%);
}

@keyframes new-log-snapshot-animation {
    from {
        opacity: 0.3;
    }
    to {
        opacity: 1;
    }
}

.log-photo-item {
    cursor: pointer;
    width: var(--photo-view-image-width);
    border-radius: 0.3em;
    box-shadow: var(--log-item-box-shadow);
    overflow: hidden;
    /* transform-origin: left;
    animation: new-log-animation 0.3s ease-in; */
}
.dark .log-photo-item {
    background-color: var(--ascloud-component-bg);
}
.log-photo-item.warning {
    background-color:#ffd7dd;
}
.dark .log-photo-item.warning {
    background-color:#E7B2B7;
}

.log-photo-item .gv-snapshot {
    position: relative;
    width: var(--photo-view-image-width);
    height: var(--photo-view-image-height);
    border-radius: 0.3em 0.3em 0 0;
    background-position: center calc(50% + 10px);
    background-size: 15%;
}
.log-photo-item .gv-snapshot img {
    transition: transform 0.3s ease-in;
    border-radius: 0;
}
.log-photo-item .gv-snapshot img.non-ratio-width {
    height: auto;
}
.log-photo-item .gv-snapshot img.non-ratio-height {
    width: auto;
}
/* .log-photo-item .gv-snapshot img.loaded {
    animation: new-log-snapshot-animation 1s ease-in;
} */
.log-photo-item .gv-snapshot:hover img:not(.common-plate-no-photo > img) {
    transform: scale(1.05);
}

.log-photo-item .gv-snapshot .common-plate-no-photo {
    bottom: 10px;
    right: 8px;
}
.log-photo-item .gv-snapshot:hover .common-plate-no-photo {
    opacity: 0;
}

.log-photo-item-image-tip {
    position: absolute;
    background-color: #00000060;
    color: #fff;
    padding: 4px 6px;
    top: 0.2rem;
    left: 0.2rem;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    border-radius: 100px;
    font-size: calc(var(--photo-view-font-size) - 2px);
}
.log-photo-item-image-tip span:empty::before {
    content: "\200b";   /* unicode zero width space character */
}

.log-photo-item-image-tip .log-time {
    margin-bottom: 1px;
    display: flex;
    align-items: center;
}
.log-photo-item-image-tip .log-time > div {
    line-height: 100%;
}

.log-photo-factors {
    position: absolute;
    top: 0.2rem;
    right: 0.3rem;
    display: flex;
    flex-direction: column;
    grid-gap: 0.15rem;
}
.log-photo-factors > div {
    background-color: #00000060;
    border-radius: 50%;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.log-photo-factors > div:not(.active) {
    opacity: .5;
}
.log-photo-factors > div > svg {
    width: 20px;
    height: 20px;
}
.log-photo-factors svg > path { 
    fill: #fff;
}

.log-photo-item-content {
    display: flex;
    align-items: center;
    padding: 0.3em;
}

.log-photo-item-photo {
    flex: 0 0 auto;
    margin-right: 0.5em;
    height: var(--photo-view-photo-size);
    width: var(--photo-view-photo-size);
}

.log-photo-item-description {
    flex: 1 1 auto;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-self: center;
    overflow: hidden;
    margin-right: 2px;
    margin-top: 4px;
    font-size: var(--photo-view-font-size);
}
.warning .log-photo-item-description {
    color: #f22;
}
.dark .warning .log-photo-item-description {
    color: #B41A16;
}
.log-photo-item-description * {
    overflow : hidden;
    text-overflow : ellipsis;
    white-space : nowrap;
    line-height: 1.4;
}

.log-photo-item .gv-snapshot:hover .log-time > div {
    display: none;
}
.log-photo-item .gv-snapshot:hover .log-time::before {
    content: attr(data-localtime);
    line-height: 100%;
}


.log-photo-item-content .common-plate-no-text {
    flex: 0 0 auto;
    position: relative;
    margin-right: 4px;
    height: 54px !important;
    overflow: hidden;
    transition: opacity .3s, width 0s;
}
.log-photo-item-content:hover .common-plate-no-text.ready {
    width: 0 !important;
    opacity: 0;
    transition: opacity .3s, width 0s ease-in .3s;
}

.log-photo-view-loading {
    width: 100%;
    margin: 1em;
}
/* End of Photo view */


/* List view */
.log-list-view {
    --list-view-image-width: 90px;
    --list-view-image-height: 60px;
    --list-view-item-height: 80px;
    --list-view-photo-size: 60px;

    --list-view-header: #88a1be;
    --list-view-header-hover: #a0b4cb;

    --log-item-box-shadow: none;
}
.dark .log-list-view {
    --list-view-header: #313336;
    --list-view-header-hover: #4c525f;
}

.log-list-table {
    text-align: left;
    width:100%;
    min-width:800px;
}

.log-list-table > thead {
    position: sticky;
    top: 0;
    background-color: var(--gv-body-bg);
    z-index: 1;
}
.log-list-table > thead th {
    font-weight: 500;
    padding: 0;
    white-space: nowrap;
}
.log-list-table > thead th div {
    background-color: var(--list-view-header);
    color: var(--ascloud-system-font-color);
    padding: 5px 10px;
}
.log-list-table > thead th:first-child div {
    border-top-left-radius: var(--gv-btn-border-radius);
    padding-left: 15px;
}
.log-list-table > thead th:last-child div {
    border-top-right-radius: var(--gv-btn-border-radius);
}

.log-list-table-sort-header {
    cursor: pointer;
    transition: background-color .3s;
}
.log-list-table-sort-header:hover {
    background-color: var(--list-view-header-hover);
}
.log-list-table-sort-header svg {
    opacity: 0;
    width: 16px;
    height: 16px;
    margin-left: 0.2em;
    transition: opacity .3s;
}
.log-list-table-sort-header:hover svg, .log-list-table-sort-header.focus svg {
    opacity: 1;
}


.log-list-table > tbody tr {
    height: var(--list-view-item-height);
    cursor: pointer;
    transition: background-color .3s;
    box-shadow: var(--log-item-box-shadow);
}
.log-list-table > tbody tr.warning {
    color: #f22;
}
.log-list-table > tbody tr:nth-child(odd) {
    background-color: var(--gv-list-item-bg);
}
.log-list-table > tbody tr:nth-child(even) {
    background-color: var(--gv-list-item-even-bg);
}
.log-list-table > tbody tr:hover {
    background-color: var(--gv-list-item-hover-bg);
}
.log-list-table > tbody td {
    max-width: 200px;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.log-list-table > tbody td:first-child {
    padding-left: 15px;
}

.log-list-table-cell {
    max-height: var(--list-view-item-height);
    display: -webkit-box;
    overflow-wrap: break-word;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    box-orient: vertical;
    -webkit-line-clamp: 3;
    -moz-line-clamp: 3;
    -ms-line-clamp: 3;
    line-clamp: 3;
    overflow: hidden;
}

.log-list-table .gv-snapshot {
    width: var(--list-view-image-width);
    height: var(--list-view-image-height);
}

.log-list-table-photo {
    height: var(--list-view-photo-size);
    width: var(--list-view-photo-size);
}
.log-list-table-cell > .log-list-table-photo + span {
    margin-left: 0.5em;
}
.log-list-table-cell.user-photo {
    white-space: nowrap;
    display: flex;
    align-items: center;
}
.log-list-table .common-plate-no-photo {
    position: relative;
}
.log-list-table-cell.log-time:hover > span {
    display: none;
}
.log-list-table-cell.log-time:hover::before {
    content: attr(data-localtime);
}

.log-list-view-loading {
    /* width: 100%; */
    margin: 1em;
    text-align: center;
}
/* End of List view */


/* Log view Setting */
.log-view-setting-modal {
    --ascloud-log-view-preview-wraper-bg: #fff;
}
.dark .log-view-setting-modal {
    --ascloud-log-view-preview-wraper-bg: #313336;
}
.log-view-setting-modal .ant-modal-body {
    display: flex;
    min-height: 500px;
    padding-left: 2rem;
}

.log-view-setting-form .ant-form-item {
    margin-bottom: 1em;
}
.log-view-setting-form .ant-form-item-label {
    text-align: left;
}

.log-view-setting-form-inline > .ant-form-item-row > .ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content {
    display: flex;
}
.log-view-setting-form-inline > .ant-form-item-row > .ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content .ant-form-item {
    margin-bottom: 0;
}
.log-view-setting-form-inline .ant-form-item-control-input-content > .fill  {
    flex: 1 1 auto;
}
.log-view-setting-form-inline .ant-form-item-control-input-content > .fill.right {
    margin-left: 0.5em;
}
.log-view-setting-form-inline .ant-form-item-control-input-content > .fill.left {
    margin-right: 0.5em;
}
.log-view-setting-form-inline .ant-form-item-control-input-content > span {
    width: 42px;
    text-align: right;
    color: var(--gv-body-color);
}


.log-view-setting-form-wrap {
    position: relative;
    flex: 1 1 auto;
    max-width: 480px;
}
.log-view-setting-form-wrap > button {
    position: absolute;
    left: 0;
    bottom: 0;
}

.log-view-setting-form-frame {
    color: var(--gv-body-color);
    position: relative;
    border: 1px solid var(--asCloud-border-color);
    border-radius: 0.5em;
    padding: 1em;
    /* margin-bottom: 1em; */
}
.log-view-setting-form-frame.global {
    border-color: transparent;
}
.log-view-setting-form-frame > span {
    position: absolute;
    top: 0;
    left: 50%;
    font-size: 18px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    background-color: var(--gv-modal-bg);
    padding: 0 0.3em;
}

.log-view-setting-gate-separator, .log-view-setting-date-separator {
    width: 60px;
    margin-right: 0.5em;
}

.log-view-setting-preview {
    flex: 1 1 auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.log-view-setting-preview-warp {
    /* border: 2em solid #f7f7f7; */
    border-radius: 0.5em;
    display: inline-block;
    background-color: var(--ascloud-log-view-preview-wraper-bg);
}
.log-view-setting-preview .log-photo-item {
    margin: var(--photo-view-item-padding);
    cursor: initial;
}

.log-view-setting-preview-warp .common-plate-no-text {
    transition: none;
}

/* End of Photo view Setting */

/* Log view print */
.log-view-print-wrap.log-list-view {
    opacity: 0;
}
@media print {
    @page {
        /* size: var(--log-view-page-size); */
        margin: 0.5cm;
    }

    .log-view-print-wrap.log-list-view {
        opacity: 1;
    }

    .log-view-print {
        text-align: center;
        border: none;
    }

    .log-list-table tr {
        page-break-inside: avoid;
    }

    .log-list-table > thead {
        position: static !important;
    }
}
/* End of Log view print */

.export-dropdown .ant-popover-inner-content {
    min-width: 180px;
}
.export-dropdown .ant-form {
    display: flex;
    flex-direction: column;
}
.export-dropdown .ant-form-item {
    margin-bottom: 1em;
}
.export-dropdown .ant-form > span {
    font-size: 12px;
    color: #f19696;
    text-align: center;
    margin-top: 0.5em;
}


/* Log view Filter */
.log-view-filter-form {
    flex: 0 0 auto;
    background-color: var(--ascloud-second-bg);
    padding: 4px;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    transition: max-height .3s;
    max-height: 56px;

    --log-view-filter-max-height: 110px;
}
.log-view-filter-form.expand {
    max-height: var(--log-view-filter-max-height);
}
.log-view-filter-form > .main {
    flex: 1 1 auto;
}

.log-view-filter-form .row {
    margin-bottom: 10px;
    margin-top: 10px;
}

.log-view-filter-form > .buttons {
    flex: 0 0 auto;
    display: flex;
    grid-gap: 0.5em;
    align-self: flex-start;
    padding: 10px;
    padding-left: 20px;
}

.log-view-filter-form > .buttons button {
    width: 30px;
    height: 30px;
    padding: 0;
    border: none;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    float:left;
    margin:auto 0;
    background-color: var(--gv-btn-primary-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
}
.log-view-filter-form > .buttons button:hover {
    background-color: var(--gv-btn-primary-hover-bg);
}
.log-view-filter-form > .buttons button:disabled {
    background-color: var(--gv-btn-primary-disabled-bg);
    pointer-events: none;
}
.log-view-filter-form > .buttons button:disabled .ant-spin {
    color: currentColor;
    line-height: 9px;
}

.log-view-collapse-btn {
    padding: 14px;
    cursor: pointer;
}
.log-view-collapse-btn > svg {
    width: 12px;
    height: 12px;
}
/* End of Log view Filter */