/* Overwrite Ant Design */
.ant-picker {
    flex: 1 1 auto;
    border-radius: var(--gv-input-border-radius) !important;
    font-size: 1rem;
    padding: 0.375rem 0.75rem;
}

.ant-picker-input > input {
font-size: 1rem;
}
/* End of Overwrite Ant Design */


.usersetting-fields-panel {
    display: flex;
    margin-bottom: 0.5rem;
}

.usersetting-fields {
    flex: 1 1 auto;
    text-align: left;
    padding: 0 4em;
}
@media screen and (max-width: 1200px) {
    .usersetting-fields {
        padding: 0 2em 0 1em;
    }
}

.usersetting-fields .ant-form-item-label {
    padding-bottom: 4px;
}

.usersetting-org-fields input::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    background: url(../../images/accordion_arrowdown.svg) no-repeat;
    background-position: center center;
}
.usersetting-org-fields .form-label {
    display: flex;
    align-items: center;
    grid-gap: 0.5em;
}

.usersetting-photo-upload-panel {
    --usersetting-photo-width: 256px;
    flex: 0 0 auto;
    text-align: center;
}
@media screen and (max-width: 1200px) {
    .usersetting-photo-upload-panel {
        --usersetting-photo-width: 192px;
    }
}
.usersetting-photo-upload-panel>span {
    font-size: 2rem;
    font-weight: bold;
    color: #383838;
    max-width: var(--usersetting-photo-width);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
}
.dark .usersetting-photo-upload-panel>span {
    color: var(--ascloud-form-label-color);
}

.usersetting-photo-upload {
    height: var(--usersetting-photo-width) !important;
    width: var(--usersetting-photo-width) !important;
    border-radius: 50% !important;
    min-width: 0 !important;
    max-width: none !important;
    cursor: pointer;
    flex-grow: 1 !important;
    padding: 0 !important;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    max-height: 100%;
    display: flex;
    -webkit-mask-image: url(../../images/add_photo.svg);
    mask-image: url(../../images/add_photo.svg);
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    background-color: #A1A3A8;
    border-color: transparent !important;
}
.usersetting-photo-upload:hover {
    background-color: var(--gv-btn-primary-bg);
}
.usersetting-photo-upload:focus, .usersetting-photo-upload:active, .usersetting-photo-upload.file-drop-dragging-over-target {
    background-color: var(--gv-btn-primary-bg);
}
.usersetting-photo-upload.uploaded {
    -webkit-mask-image: none;
    mask-image: none;
}
.usersetting-photo-upload.disabled {
    pointer-events: none;
}
.usersetting-photo-upload>.img-wrap {
    position: relative;
    width: var(--usersetting-photo-width);
    height: var(--usersetting-photo-width);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--gv-modal-bg);
    border: 1px solid lightgray;
    border-radius: 128px;
}
.dark .usersetting-photo-upload>.img-wrap {
    border-color: #9ec5e8;
}
.usersetting-photo-upload>.img-wrap>img {
    width: calc(var(--usersetting-photo-width) - 16px);
    height: calc(var(--usersetting-photo-width) - 16px);
    border-radius: 50%;
    object-fit: cover;
}

.usersetting-photo-upload .img-wrap-buttons {
    position: absolute;
    top: 18px;
    right: 1rem;
    display: flex;
    grid-gap: 0.2rem;
    opacity: 0;
    transition: opacity 0.3s ease-in;
}
.usersetting-photo-upload:hover .img-wrap-buttons {
    opacity: 1;
}
.usersetting-photo-upload .img-wrap-buttons svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.usersetting-photo-upload>span {
    margin-top: 120px;
    font-size: 12px;
    color: #383838;
}
.usersetting-photo-upload>input {
    display: none;
}


.usersetting-cards-panel {
    border: none;
    background: var(--gv-modal-bg);
}
.usersetting-cards-panel > .card-header {
    background-color: transparent;
    border: none;
    max-width: 100%;
    padding-bottom: 0px;
    display: flex;
    grid-gap: 1rem;
    justify-content: space-between;
    padding: 0 1rem 0.5rem 1rem;
}

.usersetting-cards {
    flex: 1 1 auto;
    display: flex;
    padding-top: 0.5rem;
    overflow-x: scroll;
    overflow-y: hidden;
}
.usersetting-cards-item, .usersetting-cards-add {
    padding: 5px;
    position: relative;
    white-space: nowrap;
}
.usersetting-cards-item-label {
    position: relative;
    border-radius: var(--gv-btn-border-radius);
    -webkit-user-select: none;
    user-select: none;
    background: #E2E6ED;;
    color: var(--gv-body-color);
    cursor: pointer;
    height: 100%;
    min-width: 130px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 0.1rem;
    padding: 0 0.5rem;
}
.dark .usersetting-cards-item-label {
    background: #3B3C43;
}
.usersetting-cards-item-label-factors {
    display: flex;
    align-items: center;
    grid-gap: 0.2rem;
}
.usersetting-cards-item-label-factors > svg {
    width: 20px;
    height: 20px;
}
.usersetting-cards-item-label-factors > svg path {
    fill: var(--gv-body-color);
}
.usersetting-cards-item-label-factors > svg rect {
    stroke: var(--gv-body-color);
}
.usersetting-cards-item-label-factors > svg.symbol {
    width: 48px;
    height: 40px;
    background-color: #A1A3A834;
    padding: 4px 8px;
    border-radius: 6px;
}
.usersetting-cards-item-label-factors > svg:not(.symbol) {
    opacity: .7;
}


.usersetting-cards-add>button {
    width: 100px;
    height: 80px;
    background-color: #41b5c3 !important;
    border-color: #41b5c3 !important;
}
.usersetting-cards-add>button>svg {
    width: 36px;
    height: 36px;
}
.usersetting-cards-add>button>svg>path { 
    fill: #fff !important;
}
.usersetting-cards-add>button:hover {
    background-color: #379DAA !important;
    border-color: #379DAA !important;
}

.usersetting-cards-item.active>.usersetting-cards-item-label {
    position: relative;
    background: #daeaf5;
}
.dark .usersetting-cards-item.active>.usersetting-cards-item-label {
    color: #fff;
    background: #404E62;
}
.usersetting-cards-item-invalid {
    color: #ffa500;
    position: absolute;
    left: 0;
    top: 0.5rem;
    width: 20px;
    height: 20px;
}

.usersetting-cards-delete {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: -7px;
    right: -2px;
}
.usersetting-cards-delete>svg {
    width: 24px;
    height: 24px;
}
.usersetting-cards-item.active .usersetting-cards-delete, .usersetting-cards-item:hover .usersetting-cards-delete {
    -webkit-transition: opacity 0.3s ease-in;
    -moz-transition: opacity 0.3s ease-in;
    -o-transition: opacity 0.3s ease-in;
    transition: opacity 0.3s ease-in;
    opacity: 1;
}

.usersetting-cards-advanced {
    align-self: flex-end;
    margin-bottom: 0.5rem;
}


.cardsetting-panel {
    /* border: 1px solid var(--asCloud-border-color); */
    -webkit-transition: transform 0.3s ease-in-out;
	-moz-transition: transform 0.3s ease-in-out;
	transition: transform 0.3s ease-in-out;
    transform-origin: top;
    position: relative;
    /* top: -1px; */
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    min-height: 100px;
    /* margin-top: 1em; */
    /* overflow-x: auto; */
    overflow: visible;
    overflow-x: hidden;
    padding: 0;
}
.cardsetting-panel.hidden {
    transform: scaleY(0);
}
/* 
.cardsetting-form {
    padding: 0 10%;
}
@media screen and (max-width: 1200px) {
    .cardsetting-form {
        padding: 0 32px 0 0;
    }
}
.cardsetting-form .ant-form-item-label {
    width: 140px;
}
.cardsetting-form .as-ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: unset;
} */

.cardsetting-form-card-no .ant-input-group-compact {
    display: flex;
}
.cardsetting-form-card-no .ant-select {
    min-width: 120px;
    max-width: 160px;
}
.cardsetting-form-card-no .ant-form-item-explain-error {
    white-space: nowrap;
}


.usersetting-accessrule-row {
    display: flex;
    grid-gap: 0.5rem;
    position: relative;
}
.usersetting-accessrule-row .ant-form-item {
    flex: 1 1 auto;
}
.usersetting-add-accessrule {
    cursor: pointer;
    position: absolute;
    left: calc(100% + 6px);
    top: 4px;
}
.usersetting-mulitselect-option {
    font-size: 1rem;
    padding: 0.5rem 1rem;
}
.usersetting-mulitselect-option {
    flex-direction: row-reverse;
}
.usersetting-mulitselect-option > span {
    margin-right: 10px;
}
.usersetting-mulitselect-option:not(.ant-select-item-option-selected) > div {
    margin-left: 26px;
}

.usersetting-modal {
    max-width: 1460px;
}
.usersetting-modal > .ant-modal-content > .ant-modal-body {
    padding: 0 1rem;
}
.usersetting-modal .app-content-main {
    max-height: calc(100vh - 160px);
    /* height: 735px; */
}


.access-obj-panel {
    display: flex;
    grid-gap: 1rem;
    margin: 0 1rem;
    background-color: #daeaf5;
    border-radius: 0.5rem;
    padding: 0 6%;
    position: relative;
}
.dark .access-obj-panel {
    background-color: var(--ascloud-component-bg);
}

.access-obj-form {
    flex: 1 1 auto;
    padding-top: 1rem;
    align-self: flex-start;
    min-height: 200px;

    --access-obj-label-width: 140px;
}
.access-obj-form .ant-form-item-label {
    padding-bottom: 4px;
    width: var(--access-obj-label-width);
    text-align: left;
}
.access-obj-form .ant-form-item.required .ant-form-item-label > label:before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    line-height: 1;
    content: "*";
}


.access-obj-subfactor-col {
    display: flex;
}
.access-obj-subfactor-col > *:first-child {
    width: var(--access-obj-label-width);
}
.access-obj-subfactor-col > *:last-child {
    flex: 1 1 auto;
    width: auto;
}
.access-obj-subfactor-col .ant-form-item-explain-connected {
    position: absolute;
}

.access-obj-readonly {
    position: absolute;
    right: 0.5rem;
    bottom: 0;
    z-index: 1;
}


/* batch edit user */
.batch-user-modal > .ant-modal-content > .ant-modal-body {
    display: flex;
    overflow: hidden;
    height: 580px;
    grid-gap: 1rem;
}
.batch-user-list {
    width: 200px;
    flex: 0 0 auto;
}
.batch-user-list .list-group {
    flex: 1 1 auto;
}
.batch-user-list .list-group-item {
    white-space: nowrap;
    display: flex;
    align-items: center;
    grid-gap: 0.5em;
}
.batch-user-list .list-group-item .gv-user-avatar { 
    width: 48px;
    height: 48px;
}

.batch-user-form {
    flex: 1 1 auto;
}
.batch-user-form-chk .ant-form-item {
    margin-bottom: 0;
}
/* .batch-user-form-content {
    padding-left: 24px;
} */
.batch-user-organization .ant-form-item-label > label {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
}
.batch-user-form-divider {
    width: 100%;
    position: relative;
    border-top: 1px solid var(--asCloud-border-color);
    margin: 12px 0 16px;
}
.batch-user-form-divider > span {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--gv-body-color);
    background-color: var(--gv-modal-bg);
    padding: 0 1rem;
    cursor: default;
}
/* end of batch edit user */